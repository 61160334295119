var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"open-dropdown-v2-wrapper validated-input-container"},[_c('transition-group',{staticClass:"open-dropdown-options-wrapper",class:{
      'horizontal-layout': _vm.horizontalLayout,
      'grid-layout': _vm.gridLayout
    },attrs:{"appear":"","name":"listFade","tag":"div"}},_vm._l((_vm.options),function(option){return _c('button',{key:option.value,staticClass:"open-dropdown-option",class:{
        selected: option.value === _vm.value,
        'vertical-layout': !_vm.horizontalLayout,
        'grid-layout': _vm.gridLayout
      },on:{"click":function($event){return _vm.selectOption(option)}}},[_c('p',{staticClass:"open-dropdown-label"},[_vm._v(" "+_vm._s(option.label)+" ")])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
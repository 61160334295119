<template>
  <div class="open-dropdown-v2-wrapper validated-input-container">
    <transition-group
      appear
      name="listFade"
      tag="div"
      class="open-dropdown-options-wrapper"
      :class="{
        'horizontal-layout': horizontalLayout,
        'grid-layout': gridLayout
      }"
    >
      <button
        v-for="option in options"
        :key="option.value"
        @click="selectOption(option)"
        class="open-dropdown-option"
        :class="{
          selected: option.value === value,
          'vertical-layout': !horizontalLayout,
          'grid-layout': gridLayout
        }"
      >
        <p class="open-dropdown-label">
          {{ option.label }}
        </p>
      </button>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    horizontalLayout: {
      type: Boolean,
      default: true
    },
    gridLayout: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Boolean, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedOption: '',
      menuOpen: false
    };
  },
  mounted() {
    // toString is added because the component has accepts boolean types for value
    if (!(this.value === null || this.value === undefined)) {
      this.selectedOption = this.value.toString();
    }
  },
  watch: {
    value() {
      this.selectedOption = this.value;
    }
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option.value.toString();
      this.$emit('select', option.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.open-dropdown-v2-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .open-dropdown-title {
    color: $grey-darker;
  }

  .open-dropdown-options-wrapper {
    display: flex;
    width: 100%;

    @include respond-to(md) {
      &.horizontal-layout {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &.grid-layout {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .open-dropdown-option {
      height: 44px;
      display: flex;
      flex: 1;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      color: $black;
      border: 1px solid #e8e8e8;
      border-radius: $border-radius-v2;
      background-color: $white;
      transition: all 0.2s ease-in;
      cursor: pointer;
      margin-left: 0px;
      margin-bottom: 16px;

      outline: none;
      &:focus {
        outline: none;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
      &:not(:last-child) {
        border-right: 0;
      }

      @include respond-to(md) {
        min-height: 50px;
        margin-bottom: 12px;
        &.vertical-layout {
          flex: auto;
          margin-left: 0px;
        }

        &.grid-layout {
          &:nth-child(even) {
            margin-left: 2%;
            width: 46%;
          }
          &:nth-child(odd) {
            margin-right: 2%;
            width: 46%;
          }
        }
      }

      .open-dropdown-label {
        font-size: 13px;
        font-weight: 900;
        color: #505050;
        margin: 0;
        font-family: 'Avenir';
        margin-top: 3px;
      }

      &.selected {
        background-color: var(--color-primary);

        p {
          color: $white;
        }
      }
    }
  }
}
</style>

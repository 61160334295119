<template>
  <div class="widget-container">
    <div :class="[{ 'align-items-top': isCarClarity }, 'calculator-container']">
      <div class="user-inputs-container">
        <Slider
          :defaultValue="initLoanAmount"
          @value="updateLoanAmount"
          class="loan-slider-container"
          :bold="isCarClarity"
          :isCarClarity="isCarClarity"
        />
        <Slider
          :minValue="1"
          :maxValue="7"
          :defaultValue="initYears"
          :step="1"
          :isCurrency="false"
          label="Loan years"
          @value="updateYears"
          :bold="isCarClarity"
          :isCarClarity="isCarClarity"
          class="loan-years-container loan-slider-container"
        />
        <div
          :class="[
            {
              'balloon-container': !isCarClarity,
              'balloon-container-md slider-container': isCarClarity
            }
          ]"
        >
          <div class="balloon-header">
            <div class="balloon-header-text">
              <div>
                <div class="balloon-header-text-message">
                  <span> Do you want a balloon payment? </span>
                  <span>
                    <v-popover
                      offset="0"
                      class="popover v-popover"
                      :popoverInnerClass="[{ 'spacing-none': isCarClarity }]"
                    >
                      <font-awesome-icon
                        icon="info-circle"
                        class="info-circle"
                      />
                      <tooltip
                        slot="popover"
                        :tip-text="toolTipMessage"
                        direction="up"
                        :tooltip-styles="{
                          maxWidth: '400px',
                          backgroundColor: '#505050',
                          zIndex: 1000
                        }"
                        :dismiss="true"
                      />
                    </v-popover>
                  </span>
                </div>
              </div>
              <transition name="fade">
                <small
                  v-if="years > 5 && isBalloonPayment"
                  class="balloon-not-allowed-msg"
                  >Balloon payment not allowed for loans longer than 5
                  years</small
                >
              </transition>
            </div>
            <Toggle v-model="isBalloonPayment" :disabled="years > 5" />
          </div>
          <transition name="fade">
            <Slider
              v-if="isBalloonPayment"
              class="loan-slider-container ballon-payment"
              :minValue="0"
              :maxValue="40"
              :defaultValue="balloonRateValidation"
              :step="1"
              :isCurrency="false"
              :isPercentage="!isCarClarity"
              :disabled="years > 5 ? true : false"
              label="Balloon percent"
              @value="updateBalloonRate"
              :bold="isCarClarity"
              :isCarClarity="isCarClarity"
            />
          </transition>
        </div>
        <CreditHistory
          :options="creditHistoryOptions"
          :initValue="rate"
          @value="updateCreditHistory"
          :bold="isCarClarity"
          :isCarClarity="isCarClarity"
          :no-space="isCarClarity"
        />
      </div>
      <div class="estimated-payment-container">
        <div :class="[isCarClarity ? 'cc-payment-flex' : '']">
          <div class="col-sm-12 col-md-4 carclarity-estimated-payment-wrapper">
            <span
              :class="[
                'estimated-payment-label',
                { 'carclarity-estimated-payment-label': isCarClarity }
              ]"
              >Estimated payments*</span
            >
            <span
              :class="[
                'estimated-payment-text',
                { 'carclarity-estimated-payment-text pt-2': isCarClarity }
              ]"
              >$ {{ estimatedPayment }}</span
            >
          </div>
          <div class="payment-frequency-dropdown-v2" v-if="isCarClarity">
            <DropdownV2
              label=""
              v-model="paymentFrequency"
              :options="PAYMENT_FREQUENCY"
              @input="updatePaymentFrequency"
            />
          </div>
        </div>
        <div v-if="!isCarClarity">
          <ButtonGroup
            :options="PAYMENT_FREQUENCY"
            :value="paymentFrequency"
            @select="updatePaymentFrequency"
          />
        </div>

        <div v-if="!isCarClarity">
          <ul class="list">
            <li>
              <span> Estimated rate<sup>#</sup> </span>
              <span class="value"> {{ estimateRate }}% p.a </span>
            </li>
            <transition name="fade">
              <li v-if="isBalloonPayment">
                <span> Balloon amount </span>
                <span class="value currency">
                  {{ balloonPaymentFormatted }}
                </span>
              </li>
            </transition>
          </ul>
        </div>
        <div class="cta-container">
          <Button class-name="btn-primary w-full mt-8" @onClick="onSubmit"
            >{{ isCarClarity ? 'Apply Now' : 'Get a quote' }}
          </Button>
          <span class="cta-note" v-if="!isCarClarity"
            >No impact on your credit score
          </span>
          <!-- <span class="cta-note">No impact on your credit score </span> -->
          <div class="cta-access-text-wrapper" v-if="isCarClarity">
            <span class="cta-access-text pt-2 pb-3"
              >Access <span class="font-weight-bold">30+ lenders</span> with one
              easy application</span
            >
            <div class="col-12 col-lg-4 cta-icon-text-wrapper py-2">
              <img
                class="cta-access-text-icon"
                src="@/assets/icons/ui/check_white.png"
              />
              <span class="cta-access-text-with-icon">Expert advice</span>
            </div>
            <div class="col-12 col-lg-4 cta-icon-text-wrapper ml-3 py-2">
              <img
                class="cta-access-text-icon"
                src="@/assets/icons/ui/check_white.png"
              />
              <span class="cta-access-text-with-icon">Competitive rates</span>
            </div>
            <div class="col-12 col-lg-4 cta-icon-text-wrapper ml-3 py-2">
              <img
                class="cta-access-text-icon"
                src="@/assets/icons/ui/check_white.png"
              />
              <span class="cta-access-text-with-icon">Fast approval</span>
            </div>
          </div>
          <small class="fine-print" v-if="isCarClarity"
            >Calculations made using this calculator are intended as a guide and
            are for illustrative purposes only, and are based on the information
            you provide. Indicative repayments exclude lender fees and
            charges.</small
          >
          <small class="fine-print" v-else>
            Calculations made using this calculator are intended as a guide and
            are for illustrative purposes only, and based on the information you
            provide. *Indicative repayments excludes fees and charges.
            <sup>#</sup>Estimated rate is an average rate, and is determined by
            the applicant’s credit history selection.</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAYMENT_FREQUENCY,
  TOOLTIP_MESSAGE,
  LOAN_CALCULATOR_CTA_REDIRECT,
  LOAN_CALCULATOR_REDIRECT_URL,
  SEGMENT_WIDGET_EVENTS,
  CREDIT_HISTORY_INTEREST_RATES,
  CREDIT_HISTORY_OPTIONS
} from '@/constants';

import Slider from '@/components/Slider';
import Toggle from '@/components/Toggle';
import Button from '@/components/Button';
import ButtonGroup from '@/components/ButtonGroup';
import CreditHistory from '@/components/CreditHistory';
import Tooltip from '@/components/Tooltip';
import DropdownV2 from '@/components/DropdownV2';
import CalculatorService from '@/services/CalculatorService';

export default {
  props: {
    initBalloonPayment: {
      type: Boolean,
      default: false
    },
    initPaymentFrequency: {
      type: Number,
      default: 52
    },
    initLoanAmount: {
      type: Number,
      default: 30000
    },
    initYears: {
      type: Number,
      default: 5
    },
    initBalloonRate: {
      type: Number,
      default: 30
    },
    initRate: {
      type: Number,
      default: CREDIT_HISTORY_INTEREST_RATES['Excellent']
    },
    creditHistoryOptions: {
      type: Array
    }
  },
  components: {
    Slider,
    Toggle,
    Button,
    CreditHistory,
    ButtonGroup,
    Tooltip,
    DropdownV2
  },
  data() {
    return {
      isBalloonPayment: this.$props.initBalloonPayment,
      paymentFrequency: this.$props.initPaymentFrequency,
      PAYMENT_FREQUENCY,
      loanAmount: this.$props.initLoanAmount,
      balloonPct: this.balloonPct,
      years: this.$props.initYears,
      balloonRate: this.initBalloonRate,
      rate: this.$props.initRate,
      toolTipMessage: TOOLTIP_MESSAGE,
      isCarClarity: false,
      options: CREDIT_HISTORY_OPTIONS
    };
  },
  methods: {
    onBalloonToggle(value) {
      this.isBalloonPayment = value;
    },
    updateLoanAmount(value) {
      this.loanAmount = value;
    },
    updateYears(value) {
      this.years = value;
      if (this.years > 5) {
        this.isBalloonPayment = false;
      }
    },
    updateCreditHistory(value) {
      this.rate = value;
    },
    updatePaymentFrequency(value) {
      this.paymentFrequency = value;
    },
    updateBalloonRate(value) {
      this.balloonRate = value;
    },
    balloonPayment() {
      return (this.loanAmount * this.balloonRate) / 100;
    },
    onSubmit() {
      window.analytics.track(
        SEGMENT_WIDGET_EVENTS.LOAN_CALCULATOR_LANDING_CTA_FIRST
      );
      let src = `${LOAN_CALCULATOR_CTA_REDIRECT}?isWidget=true&`;
      if (this.years > 5 || !this.isBalloonPayment) {
        this.balloonRate = 0;
      }
      const selectedCreditRatingDepiction = this.options.filter(
        option => option.value === this.rate
      );

      let redirectUrl = this.appendQueryParams(src, {
        ...this.$route.query,
        loanAmount: this.loanAmount,
        loanTermYears: this.years,
        balloonPct: this.balloonRate,
        redirect_url: `${LOAN_CALCULATOR_REDIRECT_URL}/car-loan-calculator`,
        ...(selectedCreditRatingDepiction &&
        selectedCreditRatingDepiction.length
          ? {
              customerSuppliedCreditRatingDepiction:
                selectedCreditRatingDepiction[0].label
            }
          : {})
      });

      console.log('redirectUrl ', redirectUrl);

      var isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;
      if (isSafari) {
        // TODO: Resolve why there is a post message to carclarity to the parent.
        setTimeout(() => {
          const carClarity = {
            type: 'handleIphoneHandover',
            redirectUrl: redirectUrl
          };
          window.parent.postMessage(carClarity, '*');
        }, 2000);
      }
      window.top.location.href = redirectUrl;
    },
    validateBalloonPayment() {
      if (this.years > 5) {
        return 0;
      }
      return this.isBalloonPayment ? -this.balloonPayment() : 0;
    },
    onElementHeightChange(elm, callback) {
      var lastHeight = elm.clientHeight,
        newHeight;

      (function run() {
        newHeight = elm.clientHeight;
        if (lastHeight != newHeight) callback(newHeight);
        lastHeight = newHeight;

        if (elm.onElementHeightChangeTimer)
          clearTimeout(elm.onElementHeightChangeTimer);

        elm.onElementHeightChangeTimer = setTimeout(run, 200);
      })();
    },
    sendBodyHeightToParent() {
      const bodyHeight = this.getBodyHeight();
      window.parent.postMessage(bodyHeight, '*');
    },
    getBodyHeight() {
      return document.body.clientHeight + 50;
    },
    appendQueryParams(src, queryParams) {
      for (let key in queryParams) {
        src += key + '=' + queryParams[key] + '&';
      }
      return src;
    }
  },
  computed: {
    estimatedPayment() {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(
        -CalculatorService.periodicPayment(
          this.rate,
          this.loanAmount,
          this.years,
          this.paymentFrequency,
          this.validateBalloonPayment()
        )
      );
    },
    estimateRate() {
      return this.rate * 100;
    },
    balloonPaymentFormatted() {
      if (this.years > 5) {
        return 0;
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(this.balloonPayment());
    },
    balloonRateValidation() {
      if (this.years > 5) {
        return 0;
      }
      return this.balloonRate;
    }
  },
  watch: {
    initBalloonPayment(val) {
      this.isBalloonPayment = val;
    },
    initLoanAmount(val) {
      this.initLoanAmount = val;
    },
    initLoanYears(val) {
      this.initYears = val;
    },
    initBalloonRate(val) {
      this.balloonRate = val * 100;
    },
    initRate(val) {
      this.rate = val;
    }
  },
  mounted() {
    const { is_car_clarity } = this.$route.query;
    this.isCarClarity = !!is_car_clarity;
    document.documentElement.style.setProperty('--color-primary', '#7100da');
    setTimeout(this.sendBodyHeightToParent, 500);
    this.onElementHeightChange(
      document.body,
      function() {
        this.sendBodyHeightToParent();
      }.bind(this)
    );
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/partials/fonts';

* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.d-flex {
  display: flex !important;
}

.user-inputs-container {
  // margin: auto;
  width: 100%;
  max-width: 384px;
  margin: 0 auto 1rem;
  @include respond-to(md) {
    margin: initial;
    max-width: initial;
    margin: auto;
  }
}

.widget-container {
  display: flex;
  flex-direction: column;
  max-width: 920px;
  margin: auto;

  @include respond-to(sm) {
    padding-bottom: 116px;
  }
}

.calculator-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @include respond-to(sm) {
    flex-direction: row;
    justify-content: space-between;

    & > div {
      width: 48%;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.slider-container {
  margin-top: 0rem;
  max-width: 421px;
  &.ballon-payment {
    margin-top: 0.5rem;
  }
}

.balloon {
  &-container {
    margin-bottom: 1.35rem;
  }

  &-container-md {
    margin-bottom: 1.5rem;
  }

  &-header {
    padding: 1rem;
    padding-bottom: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &-text {
      margin-bottom: 1rem;

      &-message {
        display: flex;

        span {
          font-family: Avenir-Roman;
        }

        .popover {
          margin-left: 0.5rem;
        }
      }
    }

    @include respond-to(sm) {
      flex-direction: row;

      &-text {
        margin-bottom: 0;
      }
    }
  }

  &-slider {
    transition: all 0.2s;
  }

  &-header-text {
    font-size: 0.88rem;
    color: #505050;
  }
}

body {
  background: #f3f3f3;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s ease-out;
}

.w-full {
  width: 100%;
}

.estimated-payment {
  &-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 384px;
    width: 100%;

    // margin: auto;
    @include respond-to(xs) {
      margin-top: 24px;
      width: 100%;
    }

    @include respond-to(md) {
      max-width: initial;
      margin: initial;
      margin-top: 60px;
      width: initial;
      margin-top: initial;
    }
  }

  &-label {
    font-size: 14px;
    height: 19px;
    font-weight: 900;
    color: #505050;
    text-align: left;
    display: block;
    font-family: Avenir;
  }

  &-text {
    color: $primary;
    font-size: 1.5rem;
    text-align: left;
    display: block;
    font-weight: 900;
    margin: 0.8rem 0;

    @include respond-to(md) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }
}

.list {
  list-style: none;
  padding: 0;
  margin-top: 0;

  li {
    display: flex;
    justify-content: space-between;
    padding: 1.3rem;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    font-size: 0.88rem;
    font-weight: 500;
    color: #505050;

    .value {
      font-weight: 900;
    }

    &:not(:last-child) {
      border-bottom: 0;
    }
  }
}

.fine-print {
  margin: 0.5rem;
  margin-left: 0;
  font-size: 12px;
  margin-top: 0.75rem;
  color: #606060;
  text-align: left;
  line-height: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: block;

  @include respond-to(xs) {
    max-width: 384px;
  }
}

.currency:before {
  content: '$';
  float: left;
  padding-right: 4px;
}

.cta {
  &-note {
    color: #303030;
    font-size: 13px;
    margin-top: 0.5rem;
    display: block;
    text-align: center;
    font-family: Avenir;
    font-weight: 500;
  }
}

.v-popper {
  &__inner {
    font-size: 0.8rem;
    background: rgba(black, 0.8);
    color: white;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
    max-width: 250px;
  }

  .tooltip-arrow {
    border-color: black;
  }
}

.info-circle {
  color: #b0b0b0;
}

.mt-8 {
  margin-top: 8px;
}

sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

.loan-years-container.slider-container {
  margin-bottom: 0.5rem;
}

.balloon-not-allowed-msg {
  font-size: 0.75rem;
  color: #606060;
  margin: 5px 0;
  display: block;
  font-family: Avenir-Roman;
  text-align: left;
}

.loan-slider-container {
  margin-bottom: 1.6rem;
}

.cta-access-text-wrapper {
  background-color: #51b37f;
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 16px 24px;
  margin-top: 20px;
  justify-content: center;

  .cta-access-text {
    position: relative;
    top: 1px;
    width: 100%;
    font-family: Avenir;
    font-weight: 500;
    text-align: center;
    margin-top: 0.1rem;
    color: #fff;
    font-size: 19px;

    > span {
      color: #fff;
      font-weight: 700 !important;
    }
  }

  .cta-icon-text-wrapper {
    display: flex;
    justify-content: center;

    .cta-access-text-with-icon {
      position: relative;
      top: 1px;
      font-family: Avenir;
      font-weight: 700;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }

    .cta-access-text-icon {
      width: 11px;
      height: 12px;
      left: 0px;
      top: 3.7px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 4px;
      position: relative;
    }
  }
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.carclarity-estimated-payment-label {
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.carclarity-estimated-payment-text {
  font-weight: 800 !important;
  margin: 0 !important;
}

@media (max-width: 576px) {
  .carclarity-estimated-payment-wrapper {
    width: 100% !important;
    max-width: 100% !important;
  }
  .carclarity-estimated-payment-text {
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 33px;
    margin: 7px 0px !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .cta-icon-text-wrapper {
    margin-left: 0px;
  }

  .cta-access-text-wrapper {
    flex-direction: column;
  }

  .cta-access-text-wrapper > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }
  .cc-payment-flex {
    display: block !important;
  }
  .payment-frequency-dropdown-v2 {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.cc-payment-flex {
  display: flex;
}
.carclarity-estimated-payment-wrapper {
  flex: auto;
  max-width: 53%;
}
.payment-frequency-dropdown-v2 {
  max-width: 47%;
  flex: auto;
}
.align-items-top {
  align-items: flex-start;
}
.spacing-none {
  > div > div {
    max-width: 250px !important;
    padding-left: 0px !important;
  }
}
</style>

<template>
  <div class="h-screen flex items-center" @click.self.stop="onCloseModal">
    <div
      id="scrollable"
      class="max-h-screen w-full pb-5 bg-grey-lightest-50 relative"
      :class="{
        'overflow-hidden': isRedirecting,
        'overflow-auto': !isRedirecting
      }"
    >
      <img class="invisible h-0" :src="partnerLogoUrl" :alt="partnerName" />
      <loading-screen
        v-if="isRedirecting"
        :partnerLogo="partnerLogoUrl"
        :partnerName="partnerName"
      />
      <div class="modal-close" @click="onCloseModal">
        <img src="@/assets/icons/ui/close.svg" />
      </div>
      <div class="flex flex-col md:flex-row px-5">
        <div class="w-full md:w-1/2 md:pr-5">
          <Slider
            :defaultValue="initLoanAmount"
            @value="updateLoanAmount"
            class="partners-calculator-slider"
          />
          <Slider
            :minValue="1"
            :maxValue="7"
            :defaultValue="initYears"
            :step="1"
            :isCurrency="false"
            label="Loan years"
            @value="updateYears"
            class="partners-calculator-slider"
          />
          <Slider
            :minValue="1"
            :maxValue="10"
            :defaultValue="initInterestRate"
            :step="0.1"
            :isCurrency="false"
            label="Interest rate"
            @value="updateInterestRate"
            class="partners-calculator-slider mb-6.5"
          />
          <div
            class="
              flex flex-col
              md:flex-row
              md:items-center
              justify-between
              mb-3
              mt:mb-2
            "
          >
            <div class="flex items-center">
              <p class="font-normal text-sm text-grey-darker mt-0.75 mr-2">
                Do you want a balloon payment?
              </p>
              <v-popover offset="0" class="popover v-popover mt-0.5">
                <font-awesome-icon
                  icon="info-circle"
                  class="text-xs mb-0.5 text-grey-light-50"
                />
                <tooltip
                  slot="popover"
                  :tip-text="toolTipMessage"
                  :tooltip-styles="{
                    maxWidth: '340px',
                    backgroundColor: '#505050',
                    zIndex: 1000
                  }"
                  direction="up"
                  :dismiss="true"
                />
              </v-popover>
            </div>

            <Toggle v-model="isBalloonPayment" />
          </div>
          <transition name="fade">
            <p
              v-if="years > 5 && isBalloonPayment"
              class="text-xxs text-grey-lighter font-normal mb-3 mt:mb-2"
            >
              Balloon payment not allowed for loans longer than 5 years
            </p>
          </transition>
          <transition name="fade">
            <Slider
              v-if="isBalloonPayment"
              class="partners-calculator-slider"
              :minValue="0"
              :maxValue="40"
              :defaultValue="balloonRateValidation"
              :step="1"
              :isCurrency="false"
              :disabled="years > 5 ? true : false"
              label="Balloon percent"
              @value="updateBalloonRate"
            />
          </transition>
        </div>
        <div class="w-full md:w-1/2 md:pl-5 mt-4 md:mt-0">
          <p class="text-sm font-extrabold text-grey-darker mb-2 leading-5">
            Estimated payments*
          </p>
          <h1
            class="
              text-2xl
              leading-8
              md:text-base-2.5
              font-extrabold
              text-primary
              mb-5
              md:leading-15
            "
          >
            $ {{ estimatedPayment }}
          </h1>

          <ButtonGroup
            class="partners-button-group"
            :options="PAYMENT_FREQUENCY"
            :value="paymentFrequency"
            @select="updatePaymentFrequency"
          />
          <ul class="partners-list">
            <li>
              <span>Interest rate</span>
              <span class="partners-list-value"> {{ interestRate }}% p.a </span>
            </li>
            <transition name="fade">
              <li v-if="isBalloonPayment">
                <span> Balloon amount </span>
                <span class="partners-list-value currency">
                  $ {{ balloonPaymentFormatted }}
                </span>
              </li>
            </transition>
          </ul>
          <div>
            <Button
              class-name="btn-var-primary btn-xlarge w-full mb-2.5"
              @onClick="onSubmit"
            >
              Get my loan options
            </Button>
            <p class="text-center text-sm font-medium mb-3">
              No impact on your credit score
            </p>
          </div>
        </div>
      </div>
      <p class="text-xs font-normal text-grey-darker pt-3 px-5">
        {{ disclaimer }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  PAYMENT_FREQUENCY,
  TOOLTIP_MESSAGE,
  SEGMENT_WIDGET_EVENTS,
  LEAD_APP_URL
} from '@/constants';

import Slider from '@/components/Slider';
import Toggle from '@/components/Toggle';
import Button from '@/components/Button';
import ButtonGroup from '@/components/ButtonGroup';
import Tooltip from '@/components/Tooltip';
import CalculatorService from '@/services/CalculatorService';
import LoadingScreen from '../components/LoadingScreen.vue';

export default {
  props: {
    initBalloonPayment: { type: Boolean, default: false },
    initPaymentFrequency: { type: Number, default: 52 },
    initLoanAmount: { type: Number, default: 30000 },
    initYears: { type: Number, default: 5 },
    initBalloonRate: { type: Number, default: 30 },
    initInterestRate: { type: Number, default: 4.5 },
    creditHistoryOptions: { type: Array },
    marketingData: { type: Object, default: () => {} },
    partnerLogoUrl: { type: String, default: '' },
    partnerName: { type: String, default: '' },
    disclaimer: {
      type: String,
      default:
        'Calculations made using this calculator are intended as a guide and are for illustrative purposes only, and based on the information you provide. Indicative repayments exclude lender fees and charges.'
    }
  },
  mounted() {
    const { googleTagManagerContainerId } = this.$route.query;
    if (googleTagManagerContainerId) {
      this.attachGTMScript(googleTagManagerContainerId);
    }
  },
  components: { Slider, Toggle, Button, ButtonGroup, Tooltip, LoadingScreen },
  data() {
    return {
      isBalloonPayment: this.$props.initBalloonPayment,
      paymentFrequency: this.$props.initPaymentFrequency,
      PAYMENT_FREQUENCY,
      loanAmount: this.$props.initLoanAmount,
      years: this.$props.initYears,
      balloonRate: this.initBalloonRate,
      interestRate: this.$props.initInterestRate,
      toolTipMessage: TOOLTIP_MESSAGE,
      isRedirecting: false
    };
  },
  methods: {
    onBalloonToggle(value) {
      this.isBalloonPayment = value;
    },
    updateLoanAmount(value) {
      this.loanAmount = value;
    },
    updateYears(value) {
      this.years = value;
    },
    updatePaymentFrequency(value) {
      this.paymentFrequency = value;
    },
    updateBalloonRate(value) {
      this.balloonRate = value;
    },
    updateInterestRate(value) {
      this.interestRate = value;
    },
    balloonPayment() {
      return (this.loanAmount * this.balloonRate) / 100;
    },
    onSubmit() {
      const scrollable = document.getElementById('scrollable');
      scrollable.scrollTop = 0;
      this.isRedirecting = true;
      window.analytics.track(
        SEGMENT_WIDGET_EVENTS.WIDGET_LOAN_CALCULATOR_CTA,
        JSON.parse(JSON.stringify(this.marketingData))
      );
      let src = `${LEAD_APP_URL}?isWidget=true&`;
      if (this.years > 5 || !this.isBalloonPayment) {
        this.balloonRate = 0;
      }
      let redirectUrl = this.appendQueryParams(src, {
        ...this.$route.query,
        loanAmount: this.loanAmount,
        loanTermYears: this.years,
        balloonPct: this.balloonRate,
        partnerLogoUrl: '',
        partnerRedirectUrl: '',
        primaryColourHexValue: '',
        secondaryColourHexValue: ''
      });

      var isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;
      if (isSafari) {
        setTimeout(() => {
          const carClarity = {
            type: 'handleIphoneHandover',
            redirectUrl: redirectUrl
          };
          window.parent.postMessage(carClarity, '*');
        }, 2000);
        window.setTimeout(() => {
          // window.open(redirectUrl, '_blank');
          this.isRedirecting = false;
          this.onCloseModal(undefined, false);
        }, 5000);
      } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        setTimeout(() => {
          window.open(redirectUrl, '_blank');
          this.isRedirecting = false;
          this.onCloseModal(undefined, false);
        }, 1000);
      } else {
        setTimeout(() => {
          window.open(redirectUrl, '_blank');
          this.isRedirecting = false;
          this.onCloseModal(undefined, false);
        }, 3000);
      }
    },
    validateBalloonPayment() {
      if (this.years > 5) {
        return 0;
      }
      return this.isBalloonPayment ? -this.balloonPayment() : 0;
    },
    appendQueryParams(src, queryParams) {
      for (let key in queryParams) {
        src += key + '=' + queryParams[key] + '&';
      }
      return src;
    },
    onCloseModal(_, showPopup = true) {
      const { modalPrimaryColourHexValue = '7100DA' } = this.$route.query;
      const carClarity = {
        type: 'handleCloseModal',
        args: {
          colour: modalPrimaryColourHexValue,
          isShowPopup: showPopup
        }
      };
      window.parent.postMessage(carClarity, '*');
    },
    attachGTMScript(gtmId) {
      const gtmScript = document.createElement('script');
      gtmScript.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${gtmId}')`;
      document.head.appendChild(gtmScript);

      // no script
      const noscript = document.createElement('noscript');
      noscript.text = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
      document.body.appendChild(noscript);
    }
  },
  computed: {
    estimatedPayment() {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(
        -CalculatorService.periodicPayment(
          this.interestRate / 100,
          this.loanAmount,
          this.years,
          this.paymentFrequency,
          this.validateBalloonPayment()
        )
      );
    },
    balloonPaymentFormatted() {
      if (this.years > 5) {
        return 0;
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(this.balloonPayment());
    },
    balloonRateValidation() {
      if (this.years > 5) {
        return 0;
      }
      return this.balloonRate;
    }
  },
  watch: {
    initBalloonPayment(val) {
      this.isBalloonPayment = val;
    },
    initLoanAmount(val) {
      this.initLoanAmount = val;
    },
    initLoanYears(val) {
      this.initYears = val;
    },
    initBalloonRate(val) {
      this.balloonRate = val * 100;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/partials/fonts';
body {
  overflow: auto;
}
.partners-calculator-slider {
  max-width: none;
  margin-top: 0;
  margin-bottom: 24px;
  height: 125px;
  padding: 15px;
  border-radius: 2px;
  flex-direction: column;
  @include respond-to(sm) {
    flex-direction: row;
    height: 80px;
  }
  ::v-deep {
    .range-container {
      width: 100%;
      flex: 1;
      min-width: auto;
    }
    .range-container label {
      margin: 0;
      font-size: 14px;
      line-height: 19px;
      color: $grey-darker;
      margin-bottom: 6px;
    }
    .range-value {
      height: 44px;
      margin-top: 10px;
      margin-left: 0;
      width: 118px;
      @include respond-to(sm) {
        width: 99px;
        margin-top: 0;
        margin-left: 50px;
      }
    }
    .range-value input {
      width: 67px;
      margin: 0 auto;
    }
  }
}
.partners-button-group {
  ::v-deep {
    .open-dropdown-options-wrapper .open-dropdown-option {
      height: 42px;
      margin-bottom: 20px;
      @include respond-to(sm) {
        height: 50px;
      }
    }
    .open-dropdown-options-wrapper .open-dropdown-option .open-dropdown-label {
      font-weight: 800;
      line-height: 17px;
    }
  }
}
.partners-list {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 20px;
  li {
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: #505050;
    height: 70px;
    padding: 24px 22px;
    @include respond-to(sm) {
      padding: 24px 35px;
    }
    .partners-list-value {
      font-weight: 800;
    }
    &:not(:last-child) {
      border-bottom: 0;
    }
  }
}
.modal-close {
  position: sticky;
  border: none;
  width: 100%;
  height: 40px;
  padding: 16px 20px 6px;
  background-color: rgb(242, 241, 246);
  display: flex;
  border-radius: 0px;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0px;
  right: 0px;
  z-index: 100;
  cursor: pointer;
}
</style>

export const SEGMENT_WIDGET_EVENTS = {
  WIDGET_CALCULATOR_CALCULATE_MY_REPAYMENTS:
    'widgetCalculatorCalculateMyRepayments',
  WIDGET_CALCULATOR_HOW_MUCH_CAN_I_BORROW: 'widgetCalculatorHowMuchCanIBorrow',
  WIDGET_LINK_LOGO: 'widgetLinkLogo',
  WIDGET_LOAN_CALCULATOR_CTA: 'widgetLoanCalculatorCTA',
  WIDGET_JOURNEY_FINANCE_GET_MY_LOAN_OPTIONS:
    'widgetJourneyFinanceGetMyLoanOptions',
  LOAN_CALCULATOR_LANDING_CTA_FIRST: 'loanCalculatorLandingCTAFirst',
  WIDGET_AFFORDABILITY_CALCULATOR_CTA: 'widgetAffordabilityCalculatorCTA'
};
export const SEGMENT_WIDGET_CATEGORY = 'Widget';
export const SEGMENT_WIDGET_LOAD_EVENTS = {
  CALCULATOR: 'Calculator',
  LOAN_CALCULATOR: 'Loan Calculator',
  JOURNEY_FINANCE: 'Journey Finance',
  AFFORDABILITY_CALCULATOR: 'Affordability Calculator'
};

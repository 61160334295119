<template>
  <div>
    <LoanCalculator
      :initLoanAmount="initLoanAmount"
      :initBalloonPayment="initBalloonPayment"
      :initYears="initYears"
      :initBalloonRate="initBalloonRate"
      :initInterestRate="initRate"
      :initPaymentFrequency="initPaymentFrequency"
      :marketingData="mx$marketingData"
      :partnerLogoUrl="partnerLogoUrl"
      :partnerName="partnerName"
      :disclaimer="disclaimer"
    />
  </div>
</template>

<script>
import {
  SEGMENT_WIDGET_CATEGORY,
  SEGMENT_WIDGET_LOAD_EVENTS
} from '@/constants';
import StartMixin from '@/mixins/start.mixin';
import LoanCalculator from '@/widgets/LoanCalculator';

export default {
  mixins: [StartMixin],
  components: { LoanCalculator },
  data() {
    let urlParams = new URLSearchParams(window.location.search);
    const initLoanAmountParam = urlParams.get('initLoanAmount');
    let initLoanAmount = 30000;
    if (initLoanAmountParam) {
      initLoanAmount = Number.parseFloat(initLoanAmountParam);
    }
    const initBalloonPaymentParam = urlParams.get('initBalloonPayment');
    let initBalloonPayment = false;
    if (initBalloonPaymentParam === 'true') {
      initBalloonPayment = true;
    }
    const initYearsParam = urlParams.get('initYears');
    let initYears = 5;
    if (initYearsParam) {
      initYears = Number.parseFloat(initYearsParam);
    }

    const initBalloonRateParam = urlParams.get('initBalloonRate');
    let initBalloonRate = 30;
    if (initBalloonRateParam) {
      initBalloonRate = Number.parseFloat(initBalloonRateParam) * 100;
    }

    const initRateParam = urlParams.get('initRate');
    let initRate = 4.5;
    if (initRateParam) {
      initRate = Number.parseFloat(initRateParam) * 100;
    }

    const initPaymentFrequencyParam = urlParams.get('initPaymentFrequency');
    let initPaymentFrequency = 52;
    if (initPaymentFrequencyParam) {
      initPaymentFrequency = Number.parseInt(initPaymentFrequencyParam);
    }

    const partnerLogoUrl = urlParams.get('partnerLogoUrl');
    const disclaimer = urlParams.get('disclaimer');
    const utmSource = urlParams.get('utm_source');
    const partnerName = urlParams.get('partnerName');

    return {
      initLoanAmount,
      initBalloonPayment,
      initYears,
      initBalloonRate,
      initRate,
      initPaymentFrequency,
      partnerLogoUrl,
      partnerName: partnerName || utmSource,
      disclaimer
    };
  },
  mounted() {
    window.analytics.page(
      SEGMENT_WIDGET_CATEGORY,
      SEGMENT_WIDGET_LOAD_EVENTS.LOAN_CALCULATOR,
      JSON.parse(JSON.stringify(this.mx$marketingData))
    );
    const { modalPrimaryColourHexValue = '7100DA' } = this.$route.query;
    document.documentElement.style.setProperty(
      '--color-primary',
      `#${modalPrimaryColourHexValue}`
    );
    document.body.style.setProperty('background', 'none', 'important');
  }
};
</script>

<template>
  <div class="w-full mt-0.5 mb-0.5 tablet:my-0">
    <input
      v-model="rangeValue"
      class="slider"
      type="range"
      :step="step"
      :min="minValue"
      :max="maxValue"
      :disabled="disabled"
      ref="rangeSlider"
    />
  </div>
</template>

<script>
export default {
  props: {
    minValue: { type: Number, default: undefined },
    maxValue: { type: Number, default: undefined },
    value: { type: [Number, String], default: 10 },
    step: { type: Number, default: 1 },
    disabled: { type: Boolean, default: false }
  },
  mounted() {
    if (this.$props.disabled) {
      return;
    }
    this.updateRangeTrack();
  },
  data() {
    let defaultValue = this.getValue(this.$props.value);
    return { selectedValue: defaultValue };
  },
  computed: {
    rangeValue: {
      get() {
        this.updateRangeTrack();
        return this.selectedValue;
      },
      set(val) {
        this.selectedValue = val;
        this.$emit('input', val);
      }
    }
  },
  watch: {
    value(val) {
      this.selectedValue = this.getValue(val);
    }
  },
  methods: {
    updateRangeTrack() {
      if (this.$refs.rangeSlider) {
        const val =
          ((this.selectedValue - this.minValue) /
            (this.maxValue - this.minValue)) *
          100;
        this.$refs.rangeSlider.style.background = `linear-gradient(to right, var(--color-primary) 0%, var(--color-primary) ${val}%, #f1f1f1 ${val}%`;
      }
    },
    getValue(value) {
      if (this.maxValue !== undefined && value > this.maxValue) {
        return this.maxValue;
      } else if (this.minValue !== undefined && value < this.minValue) {
        return this.minValue;
      }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #e5e5e5;
  border-radius: 8px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: all 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    border: 2px solid var(--color-primary);
    height: 15px;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 40px;
    border: 2px solid var(--color-primary);
    height: 15px;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  &::-moz-range-progress {
    background-color: var(--color-primary);
  }
  &::-ms-fill-upper {
    background-color: #666;
  }
  &::-ms-fill-lower {
    background-color: var(--color-primary);
  }
}
.disabled {
  opacity: 0.5;
}
</style>

<template>
  <div
    class="flex flex-col tablet:flex-row justify-between bg-white py-3.5 px-3 mb-2 rounded-sm"
  >
    <div class="flex-1 flex flex-col justify-center tablet:mr-5">
      <div class="flex items-center">
        <p
          :class="[
            infoCircleDark ? 'text-black' : 'text-grey-darker',
            'text-sm font-bold'
          ]"
        >
          {{ label }}
        </p>
        <v-popover
          v-if="tooltipMessage"
          offset="0"
          class="ml-2 popover v-popover"
        >
          <font-awesome-icon
            icon="info-circle"
            :class="[{ 'text-dark': infoCircleDark }, 'info-circle', 'text-xs']"
          />
          <tooltip
            slot="popover"
            :tip-text="tooltipMessage"
            direction="up"
            :tooltip-styles="{
              maxWidth: '200px',
              backgroundColor: '#505050',
              zIndex: 1000
            }"
            :dismiss="true"
          />
        </v-popover>
      </div>
      <RangeSlider
        v-if="hasSlider"
        :value="selectedValue"
        :minValue="minValue"
        :maxValue="maxValue"
        :step="1"
        @input="onUpdateRange"
      />
    </div>
    <div class="flex mt-3.5 tablet:mt-0 group-container tablet:justify-end">
      <vue-numeric
        v-model="selectedValue"
        :min="minValue"
        :max="maxValue"
        :precision="precision"
        class="border border-solid border-primary p-2 text-lg text-center custom-input rounded-l-sm"
        :class="{
          'rounded-r-none': hasDropdown,
          'rounded-r-sm': !hasDropdown,
          'text-grey-darker': infoCircleDark
        }"
        @input="onUpdateRange"
        @keypress.native="onKeyPress"
        @blur.native="onBlur"
      />
      <div v-if="hasDropdown" class="custom-dropdown h-full">
        <Select
          :options="options"
          :value="dropdown"
          theme="group"
          @input="onUpdateDropdown"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueNumeric from 'vue-numeric';
import RangeSlider from '@/components/RangeSlider';
import Select from '@/components/Select';
import Tooltip from '@/components/Tooltip';

export default {
  components: { VueNumeric, RangeSlider, Select, Tooltip },
  props: {
    value: { type: [String, Number], default: 0 },
    dropdown: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    options: { type: Array, default: () => [] },
    hasDropdown: { type: Boolean, default: true },
    hasSlider: { type: Boolean, default: true },
    minValue: { type: Number, default: undefined },
    maxValue: { type: Number, default: undefined },
    step: { type: Number, default: 1 },
    tooltipMessage: { type: String, default: '' },
    precision: { type: Number, default: 0 }, // decimal places
    infoCircleDark: { type: Boolean, default: false }
  },
  data() {
    let defaultValue = this.getValue(this.$props.value);
    return {
      selectedValue: defaultValue
    };
  },
  computed: {
    formatPrice: {
      get() {
        return parseFloat(this.selectedValue).toLocaleString('en');
      },
      set(val) {
        if (!val) {
          this.selectedValue = '0';
          return;
        }
        if (typeof val === 'string') {
          val = val.replace(/,/g, '');
        }
        this.selectedValue = val;
      }
    }
  },
  watch: {
    value() {
      this.selectedValue = this.value;
      this.formatPrice = this.value;
    }
  },
  methods: {
    onUpdate(e) {
      this.$emit('input', e.target.value);
    },
    onUpdateDropdown(value) {
      this.$emit('update:dropdown', value);
    },
    onUpdateRange(data) {
      this.selectedValue = data;
      this.$emit('input', data);
    },
    getValue(value) {
      if (this.maxValue !== undefined && value > this.maxValue) {
        return this.maxValue;
      } else if (this.minValue !== undefined && value < this.minValue) {
        return this.minValue;
      }
      return value;
    },
    onKeyPress(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault();
      }
    },
    /**
     * When the input loses focus
     */
    onBlur(event) {
      this.$emit('blurred', event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.group-container {
  height: 43px;
  width: 220px;
}
.custom-input {
  height: 43px;
  width: 120px;
}
.custom-dropdown {
  height: 43px;
  width: 100px;
}
input:focus {
  outline: none;
}
.popover {
  display: flex;
  &__inner {
    font-size: 0.8rem;
    background: #505050;
    color: white;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
    max-width: 250px;
  }
  ::v-deep.trigger {
    display: flex !important;
  }
  .arrow {
    background-color: #505050;
  }
  .tooltip-arrow {
    border-color: #505050;
  }
}
.info-circle {
  color: #b0b0b0;
}
.text-dark {
  color: #000000 !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-auto flex flex-wrap justify-center gap-x-2.5",class:{
      'justify-end': _vm.widgetVariant === _vm.CALCULATOR_VARIANT_2,
      'md:justify-center': _vm.widgetVariant === _vm.CALCULATOR_VARIANT_3
    }},[_c('button',{staticClass:"cc-button cursor-pointer mt-2.5 xs:mt-0 py-2 px-5\n       md:mb-0\n      ",class:{
        'cc-button-fill bg-primary text-white mb-2': [
          _vm.CALCULATOR_VARIANT_1,
          _vm.CALCULATOR_VARIANT_3
        ].includes(_vm.widgetVariant),
        'cc-button-outline mb-2.5': _vm.widgetVariant === _vm.CALCULATOR_VARIANT_2,
        'flex-grow': [_vm.CALCULATOR_VARIANT_1, _vm.CALCULATOR_VARIANT_2].includes(
          _vm.widgetVariant
        )
      },on:{"click":_vm.handleClickCalculateMyRepayments}},[_c('span',{staticClass:"cc-button-label cc-widget-font"},[_vm._v(" Calculate my repayments ")])]),_c('button',{staticClass:"cc-button cursor-pointer xs:mt-0 py-2 px-5",class:{
        'md:ml-2.5 cc-button-outline ml-0 mt-2.5': [
          _vm.CALCULATOR_VARIANT_1,
          _vm.CALCULATOR_VARIANT_3
        ].includes(_vm.widgetVariant),
        'cc-button-fill bg-primary text-white md:mr-2.5 md:ml-2.5 mt-2':
          _vm.widgetVariant === _vm.CALCULATOR_VARIANT_2,
        'flex-grow': [_vm.CALCULATOR_VARIANT_1, _vm.CALCULATOR_VARIANT_2].includes(
          _vm.widgetVariant
        )
      },on:{"click":_vm.handleClickHowMuchCanIBorrow}},[_c('span',{staticClass:"cc-button-label cc-widget-font"},[_vm._v(" How much I can borrow? ")])])]),_c('a',{staticClass:"cursor-pointer block"},[_c('div',{staticClass:"mt-5 md:mt-3 flex items-center justify-center\n      md:max-w-sm\t\n      ml-0\n      ",class:{
        'flex justify-center': _vm.widgetVariant === _vm.CALCULATOR_VARIANT_3,
        'md:justify-start': _vm.widgetVariant !== _vm.CALCULATOR_VARIANT_3,
        'md:mx-auto': _vm.widgetVariant === _vm.CALCULATOR_VARIANT_3
      }},[_c('p',{staticClass:"block text-right text-xs text-grey-dark mr-2 powered-by"},[_vm._v(" Powered by ")]),_c('img',{staticClass:"h-6 cursor-pointer",attrs:{"svg-inline":"","src":require("@/assets/images/logo/CarClarity.svg")},on:{"click":_vm.onLogoClick}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <button
    v-if="!internalLink"
    ref="button"
    :class="[{ disabled: disabled || loading || isActiveRequest }, className]"
    class="btn"
    :style="{ minWidth: buttonWidthOnLoading }"
    @click="handleClick"
  >
    <div class="buttonContentWrapper">
      <slot v-if="!(loading || isActiveRequest)">
        <p :style="{ fontSize }">
          {{ label }}
        </p>
      </slot>
      <font-awesome-icon
        v-if="loading || isActiveRequest"
        icon="spinner"
        class="loading-spinner"
      />
    </div>
  </button>
  <router-link
    v-else
    :class="[{ disabled: disabled || loading || isActiveRequest }, className]"
    :to="to"
    class="btn btn-link"
  >
    {{ label }}
  </router-link>
</template>

<script>
export default {
  props: {
    internalLink: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    label: {
      type: [String, Function],
      default: 'Submit'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      buttonWidth: '0px',
      isActiveRequest: false
    };
  },
  computed: {
    isActive() {
      return true;
    },
    fontSize() {
      if (this.className.includes('btn-large')) return '18px';
      if (this.className.includes('btn-small')) return '11px';
      return '14px';
    },
    buttonWidthOnLoading() {
      return this.loading || this.isActiveRequest ? this.buttonWidth : '';
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setWidthOfButton();
    });
  },
  methods: {
    handleClick() {
      if (!this.loading && !this.disabled) {
        this.$emit('onClick');
      }
    },
    setWidthOfButton() {
      if (!this.$refs.button) return '0px';
      const buttonWidth = this.$refs.button.clientWidth;
      if (buttonWidth) {
        this.buttonWidth = `${buttonWidth}px`;
      }
      return '0px';
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 0px;
  border: 0px;
  outline: 0px;
  border-radius: 50px;
  height: 48px;
  transition: background-color 150ms ease-out;
  padding: 0px 16px;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  &.btn-xlarge {
    font-size: 15px;
    height: 56px;
    @include respond-to(sm) {
      font-size: 18px;
      height: 70px;
    }
  }
  &.btn-large {
    height: 48px;
    padding: 0px 24px;
  }
  &.btn-small {
    height: 32px;
    padding: 8px 12px;
  }
  &.disabled,
  &.disabled:hover {
    background-color: $grey;
    color: $grey-light;
    cursor: not-allowed;
  }
  @include respond-to(md) {
    height: 60px;
    font-size: 16px;
  }
}

.btn-link.small {
  height: 32px;
  padding: 0px 16px;
  text-decoration: none;
}

/* primary button (purple) */
.btn-primary {
  background-color: $primary;
  color: color(white);
  &:hover {
    background-color: $primary-dark;
    color: color(white);
  }
  &:active {
    background-color: $primary-darker;
    color: color(white);
  }
}

.btn-secondary {
  background-color: $secondary;
  color: color(white);
  &:hover {
    background-color: $secondary-light;
    color: color(white);
  }
  &:active {
    background-color: $secondary;
    color: color(white);
  }
}

.btn-ghost-primary {
  color: $primary;
  background-color: $white;
  transition: all 150ms ease-in;
  border: 1px solid $primary;
  &:hover {
    background-color: $primary;
    color: color(white);
  }
  &:active {
    background-color: $primary;
    color: color(white);
  }
}

.btn-dotted {
  background-color: $white;
  border: 2px dashed $secondary;
  color: $grey-dark;
  &:hover {
    background-color: $secondary-light;
    border: 2px solid transparent;
    color: $white;
  }
  &:active {
    background-color: $secondary;
    color: $white;
  }
}

.btn-success {
  background-color: $success;
  color: color(white);
  &:hover {
    background-color: $success-light;
    color: color(white);
  }
  &:active {
    background-color: $success;
    color: color(white);
  }
}

.btn-error {
  background-color: $error;
  color: color(white);
  &:hover {
    background-color: $error-light;
    color: color(white);
  }
  &:active {
    background-color: $error;
    color: color(white);
  }
}
.btn-var-primary {
  background-color: var(--color-primary);
  color: color(white);
  &:hover {
    background-color: var(--color-primary);
    opacity: 0.9;
    color: color(white);
  }
  &:active {
    background-color: var(--color-primary);
    color: color(white);
  }
}
.buttonContentWrapper {
  position: relative;
  top: 1px;
  width: 100%;
  font-family: Avenir;
  font-weight: 700;
  text-align: center;
  margin-top: 0.1rem;
  .loading-spinner {
    position: relative;
    top: 1px;
    height: 16px;
    width: 16px;
  }
}
</style>

<template>
  <div class="spinner flex relative">
    <div class="spinner-item__1 w-4 h-4 rounded-full mr-2" />
    <div class="spinner-item__2 w-4 h-4 rounded-full mr-2" />
    <div class="spinner-item__3 w-4 h-4 rounded-full " />
  </div>
</template>

<script>
export default {
  name: 'Spinner'
};
</script>

<style lang="scss" scoped>
.spinner {
  .spinner-item__1,
  .spinner-item__2,
  .spinner-item__3 {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loading 1000ms infinite ease-in-out;
    animation: loading 1000ms infinite ease-in-out;
  }
  .spinner-item__1 {
    -webkit-animation-delay: -200ms;
    animation-delay: -200ms;
  }
  .spinner-item__2 {
    -webkit-animation-delay: -100ms;
    animation-delay: -100ms;
  }
}
@-webkit-keyframes loading {
  100% {
    background-color: #f5f4fc;
  }
  80% {
    background-color: #cec6ef;
  }
  0%,
  20% {
    background-color: #8c31e1;
  }
}
@keyframes loading {
  100% {
    background-color: #f5f4fc;
  }
  60% {
    background-color: #cec6ef;
  }
  0%,
  20% {
    background-color: #8c31e1;
  }
}
</style>

export const JUST_ME = 'just-me';
export const TWO_OF_US = 'two-of-us';

export const APPLICANTS_OPTION = [
  { label: 'Just me', value: JUST_ME },
  { label: 'Two of Us', value: TWO_OF_US }
];

export const AFFORDABILITY_TOOLTIP_MESSAGES = {
  [JUST_ME]: {
    WHO_IS_APPLYING:
      'How many applicants will be a part of the loan? (includes the number of borrowers and/or partners living in the same household)',
    DEPENDENTS: 'Do you have any financial dependents?',
    NET_INCOME:
      'Please enter your net income amount (after tax) and select pay frequency.',
    OTHER_INCOME:
      'Please enter any additional income (after tax), such as commission or bonuses, and select frequency. ',
    GENERAL_LIVING_EXPENSES:
      'What are your general household living expenses? Including groceries, utilities, internet, telephone, insurances, medical & entertainment.',
    RENT:
      'Do you pay rent? If yes, please enter your total rental payment and select frequency.',
    MORTGAGE:
      'Do you have a mortgage? If yes, please enter your total mortgage repayment amount and select frequency.',
    OTHER_LOAN_PAYMENTS:
      'Do you have any other loans? If yes, please enter your total repayment amount and select frequency.',
    TOTAL_CREDIT_CARD_LIMIT:
      'Please enter the total combined credit limit of any credit cards in your name.',
    CREDIT_SCORE:
      'Would you rate your current credit score as Excellent (700+), Good (500-699) or Poor (<499)? ',
    INTEREST_RATE:
      'Based on your selected credit score rating, an indicative interest rate is pre-populated for you. You may adjust this interest rate too.',
    LOAN_TERM:
      'Please select your preferred loan term. This is how many years you would like your loan repayments to be spread over.'
  },
  [TWO_OF_US]: {
    WHO_IS_APPLYING:
      'How many applicants will be a part of the loan? (includes the number of borrowers and/or partners living in the same household)',
    DEPENDENTS: 'Do you have any financial dependents? ',
    NET_INCOME:
      'Please enter your combined net income (after tax) and select pay frequency.',
    OTHER_INCOME:
      'Please enter any additional income (after tax), such as commission or bonuses, and select frequency. ',
    GENERAL_LIVING_EXPENSES:
      'What are your general household living expenses? Including groceries, utilities, internet, telephone, insurances, medical & entertainment.',
    RENT:
      'Do you pay rent? If yes, please enter your total rental payment and select frequency.',
    MORTGAGE:
      'Do you have a mortgage? If yes, please enter your total mortgage repayment amount and select frequency.',
    OTHER_LOAN_PAYMENTS:
      'Does either applicant have any other loans? If yes, please enter combined total repayment amount and select frequency.',
    TOTAL_CREDIT_CARD_LIMIT:
      'Please enter the total combined credit limit of any credit cards in your names.',
    CREDIT_SCORE:
      'Would you rate your current credit scores as Excellent (700+), Good (500-699) or Poor (<499)? ',
    INTEREST_RATE:
      'Based on your selected credit score rating, an indicative interest rate is pre-populated for you. You may adjust this interest rate too.',
    LOAN_TERM:
      'Please select your preferred loan term. This is how many years you would like your loan repayments to be spread over.'
  }
};

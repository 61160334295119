<template>
  <div
    :style="{
      position: 'relative',
      display: 'inline-block',
      maxWidth: '200px',
      paddingLeft: '35px'
    }"
  >
    <!-- <div v-if="direction" :class="['arrow-wrapper', direction]">
      <div class="arrow-container">
        <div class="arrow" />
      </div>
    </div> -->
    <div class="tooltip" :style="tooltipStyles" v-close-popover>
      <p>{{ tipText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    tipText: {
      type: String,
      default: ''
    },
    tooltipStyles: {
      type: Object,
      default: () => {}
    },
    direction: {
      // up, left, right, down
      type: String,
      required: false,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Dismiss'
    },
    dismiss: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onDismiss() {
      this.$emit('dismiss');
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip {
  max-width: 250px;
  border-radius: 2px;
  overflow-wrap: normal;
  z-index: 20;
  padding: 6px 10px 5px 10px;
  @include respond-to(sm) {
    padding: 8px;
  }
  p {
    color: $white;
    margin-bottom: 0;
    font-family: 'Avenir-Roman';
    font-size: 13px;
    text-align: left;
    @include respond-to(sm) {
      margin-top: 0;
    }
  }
}
.arrow-wrapper {
  position: absolute;
  opacity: 0.8;
  z-index: 1001;
  pointer-events: none;
  &.up {
    -webkit-transform: translateX(-50%) rotate(90deg);
    transform: translateX(-50%) rotate(90deg);
    top: -30px;
    left: 50%;

    // these styles are intended if site is within an iframe
    // TODO: should check if site is inside of an iframe
    //320
    @media only screen and (min-width: 280px) {
      left: 83% !important;
    }
    //360
    @media only screen and (min-width: 320px) {
      left: 66% !important;
    }
    //375
    @media only screen and (min-width: 335px) {
      left: 66% !important;
    }
    // 414
    @media only screen and (min-width: 374px) {
      left: 50% !important;
    }
  }
  &.up-right {
    left: 80%;
  }
  &.down {
    -webkit-transform: translateX(-50%) rotate(270deg);
    transform: translateX(-50%) rotate(270deg);
    bottom: -30px;
    left: 50%;
  }
  &.left {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 30%;
    left: -30px;
  }
  &.right {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%) rotate(180deg);
    top: 30%;
    right: -30px;
  }
}

.arrow-container {
  height: 30px;
  width: 30px;
  position: relative;
  overflow: hidden;
}
.arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: rgba(10, 9, 26, 0.7);
  -webkit-transform: rotate(45deg);
  transform: scale(0.7) rotate(45deg);
  border-radius: 1px;
  left: 24px;
  top: 9px;
}
.dismiss {
  padding: 8px;
  background-color: color(primary);
  opacity: 1;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  overflow: auto;
  border-radius: 4px;
  cursor: pointer;
  p {
    color: color(white);
    margin-bottom: none;
  }
}
.close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  @include respond-to(sm) {
    display: none;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.ddContainerClass
      ? _vm.ddContainerClass
      : 'py-3.5 bg-white mb-2 w-full flex flex-col justify-between rounded-sm'
  ]},[_c('div',{staticClass:"flex items-center mb-2 px-3"},[_c('p',{class:[
        _vm.infoCircleDark ? 'text-black' : 'text-grey-darker',
        'text-sm font-bold whitespace-nowrap mr-2'
      ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.tooltipMessage)?_c('v-popover',{staticClass:"popover v-popover",attrs:{"offset":"0"}},[_c('font-awesome-icon',{class:[{ 'text-dark': _vm.infoCircleDark }, 'info-circle', 'text-xs'],attrs:{"icon":"info-circle"}}),_c('tooltip',{attrs:{"slot":"popover","tip-text":_vm.tooltipMessage,"direction":"up","tooltip-styles":{
          maxWidth: '200px',
          backgroundColor: '#505050',
          zIndex: 1000
        },"dismiss":true},slot:"popover"})],1):_vm._e()],1),_c('Select',{attrs:{"value":_vm.value,"options":_vm.options,"placeholder":_vm.placeholder},on:{"input":_vm.onUpdate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="slider-container" :class="disabled ? 'disabled' : ''">
    <div class="range-container">
      <label :class="bold ? 'bold' : ''"> {{ label }} </label>
      <div class="field">
        <input
          v-model="rangeValue"
          class="slider"
          type="range"
          :step="step"
          :min="minValue"
          :max="maxValue"
          :disabled="disabled"
          ref="rangeSlider"
        />
      </div>
    </div>
    <div :class="[isCarClarity ? 'cc-range-value' : 'range-value']">
      <span v-if="isCurrency"> $ </span>
      <span v-else-if="isPercentage" class="percentage-label"> % </span>
      <input
        type="tel"
        v-model="formatPrice"
        @blur="formatPriceBlur"
        @keypress="isNumber($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Loan amount'
    },
    isCarClarity: {type: Boolean, default: false},
    bold: {type: Boolean, default: false},
    minValue: { type: Number, default: 5000 },
    maxValue: { type: Number, default: 250000 },
    isCurrency: { type: Boolean, default: true },
    isPercentage: { type: Boolean, default: false },
    defaultValue: { type: [Number, String], default: 30000 },
    step: { type: Number, default: 1000 },
    disabled: { type: Boolean, default: false }
  },
  data() {
    let _defaultValue;
    if (this.defaultValue > this.maxValue) {
      _defaultValue = this.maxValue;
    } else {
      _defaultValue = this.defaultValue;
    }
    return { selectedValue: _defaultValue };
  },
  mounted() {
    if (this.$props.disabled) {
      return;
    }
    this.updateRangeTrack();
  },
  computed: {
    formatPrice: {
      get() {
        this.updateRangeTrack();
        return parseFloat(this.selectedValue).toLocaleString('en');
      },

      set(val) {
        if (!val) {
          this.selectedValue = '0';
          return;
        }
        if (typeof val === 'string') {
          val = val.replace(/,/g, '');
        }
        this.selectedValue = val;
      }
    },
    rangeValue: {
      get() {
        return this.selectedValue;
      },

      set(val) {
        this.selectedValue = val;
        this.$emit('value', val);
      }
    }
  },
  methods: {
    updateRangeTrack() {
      if (this.$refs.rangeSlider) {
        const val =
          ((this.selectedValue - this.minValue) /
            (this.maxValue - this.minValue)) *
          100;
        this.$refs.rangeSlider.style.background = `linear-gradient(to right, var(--color-primary) 0%, var(--color-primary) ${val}%, #f1f1f1 ${val}%`;
      }
    },
    formatPriceBlur() {
      let value = this.selectedValue;

      if (typeof this.selectedValue === 'string') {
        value = this.selectedValue.replace(/,/g, '');
      }
      if (value > this.maxValue) {
        this.selectedValue = this.maxValue;
      }
      if (value < this.minValue) {
        this.selectedValue = this.minValue;
      }
      this.$emit('value', this.selectedValue);
    },
    isNumber(evt) {
      const keysAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.'
      ];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    }
  },
  watch: {
    defaultValue(val) {
      this.selectedValue = val;
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  font-size: 0.88rem;
  color: #505050;
  margin: 0.4rem 0;
  font-family: Avenir-Roman;
}
label.bold {
  font-weight: 800;
  font-size: 14px !important;
}
.slider-container {
  background: #fff;
  padding: 1rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  max-width: 421px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.cc-range-value {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: .5px solid var(--color-primary);
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  color: #505050;
  overflow: hidden;
  width: 35%;
  transition: all 0.2s ease-in;

  font-size: 18px;
  @media only screen and (min-width: 678px) {
    font-size: 1rem;
    margin-left: 1rem;
    width: 35%;
  }

  @media only screen and (max-width: 480px) {
    margin-top: 1rem;
    padding: 0.5rem;
    width: 100px;
  }

  span {
    padding-right: 0.1rem;
    transform: translateX(5px);
    font-size: 18px;
  }
  .percentage-label {
    transform: translate(30px);
    font-size: 13.33px;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    border: none;
    width: 80px;
    font-weight: 800;
    color: inherit;
    font-style: normal;
    font-family: Avenir;
    font-size: 18px;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;

    @media only screen and (max-width: 320px) {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
.range-value {
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #e8e8e8;
  &:focus-within {
    border: 1px solid var(--color-primary);
  }
  font-family: 'Avenir';
  font-weight: 900;
  color: #404040;
  overflow: hidden;
  width: 28%;
  transition: all 0.2s ease-in;

  font-size: 0.9rem;
  @media only screen and (min-width: 678px) {
    font-size: 1rem;
    margin-left: 1rem;
    width: 23%;
  }

  @media only screen and (max-width: 320px) {
    margin-top: 1rem;
    padding: 0.5rem;
    width: 100px;
  }

  span {
    padding-right: 0.1rem;
    transform: translateX(15px);
  }
  .percentage-label {
    transform: translate(30px);
    font-size: 13.33px;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    border: none;
    width: 80px;
    font-weight: 900;
    color: inherit;
    font-family: Avenir;
    font-size: 0.9rem;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;

    @media only screen and (max-width: 320px) {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
.range-container {
  display: flex;
  flex-direction: column;
  min-width: 200px;

  @media only screen and (min-width: 678px) {
    width: 70%;
  }
  label {
    text-align: left;
  }
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #e5e5e5;
  border-radius: 8px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: all 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    border: 2px solid var(--color-primary);
    height: 15px;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 40px;
    border: 2px solid var(--color-primary);
    height: 15px;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  &::-moz-range-progress {
    background-color: var(--color-primary);
  }
  &::-ms-fill-upper {
    background-color: #666;
  }
  &::-ms-fill-lower {
    background-color: var(--color-primary);
  }
}
.disabled {
  opacity: 0.5;
}
</style>

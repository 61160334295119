export default {
  methods: {
    mx$appendQueryParams(src, queryParams) {
      for (let key in queryParams) {
        src += key + '=' + queryParams[key] + '&';
      }
      return src;
    },
    mx$addFontStyles() {
      const {
        fontFamily,
        fontFamilyOpenTypeUrl,
        fontFamilyWoff2Url,
        fontFamilyWoffUr1,
        fontWeight
      } = this.$route.query;
      if (
        fontFamily &&
        fontFamilyOpenTypeUrl &&
        fontFamilyWoff2Url &&
        fontFamilyWoffUr1
      ) {
        const dynamicFontFamily = document.createElement('style');
        dynamicFontFamily.appendChild(
          document.createTextNode(
            `
              @font-face {
                font-family: "${fontFamily}";
                src: url("${fontFamilyWoff2Url}")  format('woff2'),
                    url("${fontFamilyWoffUr1}")  format('woff'),
                    url("${fontFamilyOpenTypeUrl}")  format('opentype');
              }
              .cc-widget-font {
                font-family: "${fontFamily}", sans-serif;
                font-weight: ${fontWeight}
              }
            `
          )
        );
        document.head.appendChild(dynamicFontFamily);
      }
    }
  }
};

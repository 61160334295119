export default {
  install(Vue) {
    const proxySegmentAnalytics = new Proxy(
      {},
      {
        get(target, name) {
          return name in target ? target[name] : () => {};
        }
      }
    );
    // fallback
    Vue.prototype.$segment = proxySegmentAnalytics;

    window.analytics.ready(() => {
      Vue.prototype.$segment = window.analytics;
    });
  }
};

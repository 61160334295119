<template>
  <div
    class="credit-container"
    :class="[{ 'slider-container': isCarClarity }, { 'space-x-0': noSpace }]"
  >
    <span
      :class="[
        'header',
        { bold: bold, 'carclarity-credit-header': isCarClarity }
      ]"
    >
      Credit history
    </span>
    <Dropdown
      :options="options"
      :value="selectedValue"
      @select="onUpdate"
      v-if="!isCarClarity"
    />
    <DropdownV2
      :x-no-space="isCarClarity"
      label=""
      v-model="selectedValue"
      :options="options"
      @input="onUpdate"
      :ddContainerClass="getContainerClass()"
      v-else
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown';
import DropdownV2 from '@/components/DropdownV2';
import {
  CREDIT_HISTORY_INTEREST_RATES,
  CREDIT_HISTORY_OPTIONS
} from '@/constants';
export default {
  props: {
    initValue: {
      type: Number,
      default: CREDIT_HISTORY_INTEREST_RATES['Excellent']
    },
    bold: { type: Boolean, default: false },
    isCarClarity: { type: Boolean, default: false },
    options: {
      type: Array,
      default: () => CREDIT_HISTORY_OPTIONS
    },
    noSpace: {
      type: Boolean,
      default: false
    }
  },
  components: { Dropdown, DropdownV2 },
  data() {
    return {
      selectedValue: this.$props.initValue
    };
  },
  methods: {
    onUpdate(value) {
      this.$emit('value', value);
    },
    getContainerClass() {
      return `${
        !this.isCarClarity ? 'pb-3 mb-2 ml-1' : ''
      } bg-white w-full flex flex-col justify-between rounded-sm`;
    }
  },
  watch: {
    initValue(val) {
      this.selectedValue = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.credit {
  &-container {
    .header {
      margin-top: 0.5rem;
      margin: 1rem;
      margin-bottom: 0.5rem;
      text-align: left;
      display: block;
      padding-left: 0;
      font-size: 0.88rem;
      margin-top: 0;
      padding-top: 0;
      color: #505050;
      margin-left: 0;
    }
    .header.bold {
      font-weight: 800 !important;
      font-size: 14px !important;
      line-height: 19px !important;
    }
    .carclarity-credit-header {
      margin-left: 0.75rem;
      margin-bottom: 0px !important;
    }
  }
}
.slider-container {
  background: #fff;
  padding: 1rem 0.25rem;
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 421px;
  @media only screen and (max-width: 480px) {
    padding: 1rem 0rem;
  }
  &.space-x-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const PAYMENT_FREQUENCY = [
  { label: 'Weekly', value: 52 },
  { label: 'Fortnightly', value: 26 },
  { label: 'Monthly', value: 12 }
];

/** Don't use NODE_ENV for environment as it is always production */
export const LOAN_CALCULATOR_CTA_REDIRECT =
  process.env.VUE_APP_ENVIRONMENT === 'production' 
    ? 'https://app.carclarity.com.au/start-application'
    : 'https://app-staging.carclarity.com.au/start-application';

/** Required for back button redirection */
export const LOAN_CALCULATOR_REDIRECT_URL =
    process.env.VUE_APP_ENVIRONMENT === 'production' 
      ? 'https://www.carclarity.com.au'
      : 'https://carclarity-website.webflow.io';

export const TOOLTIP_MESSAGE = `A balloon payment is a lump sum amount owed to the
lender at the end of a loan term after all regular monthly
repayments have been made. This allows you to repay only part of
the principal of your loan over its term, reducing your monthly
repayments in exchange for owing the lender a lump sum amount at
the end of the loan term.`;

// Marketing
/*
 * url_param: 'value on vehicle request group document root'
 * e.g. utm_source is the key, value 'lastClickUTMSource' is the property on
 * vRG root.
 */
export const VEHICLE_REQUEST_GROUP_MARKETING_PROPERTY_MAPPING = {
  utm_source: 'lastClickUTMSource',
  utm_medium: 'lastClickUTMMedium',
  utm_campaign: 'lastClickUTMCampaign',
  utm_term: 'lastClickUTMTerm',
  utm_content: 'lastClickUTMContent',
  utm_id: 'lastClickUTMId',
  partner_id: 'partnerId'
};

export const LEAD_APP_URL = process.env.VUE_APP_LEAD__URL;

// value is in percentage to decimals(6% = 0.06)
export const CREDIT_HISTORY_INTEREST_RATES = {
  Excellent: 0.06,
  Average: 0.08,
  Poor: 0.1
};

export const CREDIT_HISTORY_OPTIONS = Object.keys(
  CREDIT_HISTORY_INTEREST_RATES
).map(key => ({ label: key, value: CREDIT_HISTORY_INTEREST_RATES[key] }));

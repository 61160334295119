<template>
  <div :class="[{'disabled': disabled}, 'toggle-container']">
    <span class="secondary">No</span>
    <label class="switch">
      <input v-model="isActive" type="checkbox" :disabled="disabled" />
      <span class="slider round"></span>
    </label>
    <span class="secondary" :class="{'primary':currentState}"> Yes</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentState: this.value
    };
  },

  computed: {
    isActive: {
      get() {
        return this.currentState;
      },
      set(newValue) {
        this.currentState = newValue;
        this.$emit('input', newValue);
      }
    }
  },
  watch: {
    value(val) {
      this.currentState = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-container {
  &.disabled {
    opacity: 0.5 !important;
  }
  margin-top: -2px;
  > *:not(:last-child) {
    margin-right: 1.1rem;
  }
  display: flex;
  span {
    font-family: 'Avenir-Heavy';
    font-weight: 900;
    font-size: 13px;
    padding-top: 2px;
  }
}
.secondary {
  color: #404040;
}
.primary {
  color: var(--color-primary);
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
  margin-top: 4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -2px;

  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  background-color: var(--color-primary);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<template>
  <div
    :class="[
      { 'h-screen': !isCarClarity, 'm-width': isCarClarity },
      'flex',
      'items-center'
    ]"
    @click.self.stop="onCloseModal"
  >
    <div
      id="scrollable"
      :class="[
        {
          'max-h-screen bg-grey-lightest-50': !isCarClarity,
          'overflow-auto': !isCarClarity && !isRedirecting,
          'overflow-hidden': !isCarClarity && isRedirecting
        },
        'w-full pb-5 relative'
      ]"
    >
      <img class="invisible h-0" :src="partnerLogoUrl" :alt="partnerName" />
      <loading-screen
        v-if="isRedirecting && isValid && !isCarClarity"
        :partnerLogo="partnerLogoUrl"
        :partnerName="partnerName"
      />
      <div class="modal-close" @click="onCloseModal" v-if="!isCarClarity">
        <img src="@/assets/icons/ui/close.svg" />
      </div>
      <div
        :class="[
          { 'px-5': !isCarClarity },
          'flex flex-col md:flex-row px-sm-4 px-lg-5x'
        ]"
      >
        <div class="w-full max-w-sm md:max-w-none mx-auto md:w-1/2 md:pr-5">
          <p class="font-bold text-grey-darker text-sm mb-2">Applicant</p>
          <div class="flex flex-col tablet:flex-row justify-between mb-2">
            <div class="w-full tablet:mr-1">
              <DropdownV2
                label="Who is applying?"
                v-model="selectedApplicant"
                :options="APPLICANTS_OPTION"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant]
                    .WHO_IS_APPLYING
                "
                :infoCircleDark="isCarClarity"
              />
            </div>
            <div class="w-full tablet:ml-1">
              <DropdownV2
                label="Number of dependents?"
                v-model="numberOfDependents"
                :options="NUMBER_OF_DEPENDENDS_OPTION"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].DEPENDENTS
                "
                :infoCircleDark="isCarClarity"
              />
            </div>
          </div>
          <p class="font-bold text-grey-darker text-sm mb-2">Income</p>
          <div class="flex flex-col mb-2">
            <div>
              <InputDropdownSliderGroup
                v-model="netIncome"
                :dropdown.sync="netIncomeFrequency"
                :options="AFFORDABILITY_CALCULATOR_PAYMENT_FREQUENCY"
                label="Net income (after tax)"
                :has-slider="false"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].NET_INCOME
                "
                @blurred="onBlurInput"
                :infoCircleDark="isCarClarity"
              />
            </div>
            <div>
              <InputDropdownSliderGroup
                v-model="otherIncome"
                :dropdown.sync="otherIncomeFrequency"
                :options="AFFORDABILITY_CALCULATOR_PAYMENT_FREQUENCY"
                label="Other income (after tax)"
                :has-slider="false"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].OTHER_INCOME
                "
                :infoCircleDark="isCarClarity"
                @blurred="onBlurInput"
              />
            </div>
          </div>
          <p class="font-bold text-grey-darker text-sm mb-2">Expenses</p>
          <div class="flex flex-col mb-2">
            <div>
              <InputDropdownSliderGroup
                :max-value="5000"
                :min-value="0"
                v-model="generalLivingExpenses"
                :dropdown.sync="generalLivingExpensesFrequency"
                :options="repaymentFrequencyOptions"
                label="General living expenses"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant]
                    .GENERAL_LIVING_EXPENSES
                "
                :infoCircleDark="isCarClarity"
              />
            </div>
            <div>
              <InputDropdownSliderGroup
                :max-value="10000"
                :min-value="0"
                v-model="mortgagePayments"
                :dropdown.sync="mortgagePaymentsFrequency"
                :options="repaymentFrequencyOptions"
                label="Mortgage payments"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].MORTGAGE
                "
                :infoCircleDark="isCarClarity"
              />
            </div>
            <div>
              <InputDropdownSliderGroup
                :max-value="10000"
                :min-value="0"
                v-model="rentPayments"
                :dropdown.sync="rentPaymentsFrequency"
                :options="repaymentFrequencyOptions"
                label="Rent payments"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].RENT
                "
                :infoCircleDark="isCarClarity"
              />
            </div>
            <div>
              <InputDropdownSliderGroup
                :max-value="10000"
                :min-value="0"
                v-model="otherLoanPayments"
                :dropdown.sync="otherLoanPaymentsFrequency"
                :options="repaymentFrequencyOptions"
                label="Other loan payments"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant]
                    .OTHER_LOAN_PAYMENTS
                "
                :infoCircleDark="isCarClarity"
              />
            </div>
            <div>
              <InputDropdownSliderGroup
                :max-value="250000"
                :min-value="0"
                v-model="totalCreditCard"
                label="Total credit card limit(s)"
                :has-dropdown="false"
                :tooltip-message="
                  AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant]
                    .TOTAL_CREDIT_CARD_LIMIT
                "
                :infoCircleDark="isCarClarity"
              />
            </div>
          </div>
        </div>
        <div class="w-full max-w-sm md:max-w-none mx-auto md:w-1/2 md:pl-5">
          <p class="font-bold text-grey-darker text-sm mb-2">
            Credit History
          </p>
          <div>
            <DropdownV2
              label="How good is your credit score?"
              :value="creditScore"
              @input="onCreditScoreChange"
              :options="AFFORDABILITY_CREDIT_HISTORY_OPTIONS"
              :tooltip-message="
                AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].CREDIT_SCORE
              "
              :infoCircleDark="isCarClarity"
            />
          </div>
          <div class="mb-5">
            <InputDropdownSliderGroup
              :max-value="29"
              :min-value="0"
              v-model="interestRate"
              label="Interest Rate %"
              :has-dropdown="false"
              :precision="2"
              :tooltip-message="
                AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].INTEREST_RATE
              "
              :infoCircleDark="isCarClarity"
            />
          </div>
          <template
            v-if="
              (netIncome <= 0 && hasTriggeredBlur) ||
                (netIncome <= 0 && hasSubmitted) ||
                (calculateMonthlyDisposableIncome <= 0 && hasTriggeredBlur) ||
                (calculateMonthlyDisposableIncome <= 0 && hasSubmitted)
            "
          >
            <div class="invalid-message">
              <div class="w-full pt-3 pb-1 mb-6">
                <div class="flex flex-wrap flex-row mb-2 px-1">
                  <div class="flex-1 px-2">
                    <p class="text-sm font-bold text-grey-darker">
                      <img
                        class="warning-icon"
                        src="@/assets/icons/ui/warning.png"
                      />
                      Please recheck calculator inputs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            :class="[
              { 'bg-bgreen bg-opacity-10 mb-6': !isCarClarity },
              'w-full pt-3 pb-1 mb-5'
            ]"
          >
            <div class="flex flex-wrap flex-row">
              <div
                :class="[
                  { 'px-2 flex-1': !isCarClarity },
                  { 'pr-12': isCarClarity }
                ]"
              >
                <p class="text-sm font-bold text-grey-darker">
                  Maximum loan
                  <br class="block sm:hidden" />
                  <span> amount*</span>
                </p>
                <h1 class="text-2xl tablet:text-4.5xl font-bold text-primary">
                  <template v-if="maximumLoanAmount">
                    {{ maximumLoanAmount | currency }}
                  </template>
                  <template v-else>
                    $0
                  </template>
                </h1>
              </div>
              <div :class="{ 'px-2 flex-1': !isCarClarity }">
                <p class="text-sm font-bold text-grey-darker">
                  <span class="capitalize">{{
                    maximumRepaymentFrequency
                  }}</span>
                  <br class="block sm:hidden" />
                  <span> repayment*</span>
                </p>
                <h1 class="text-2xl tablet:text-4.5xl font-bold text-primary">
                  <template v-if="maximumMonthlyRepaymentView">
                    {{ maximumMonthlyRepaymentView | currency }}
                  </template>
                  <template v-else>$0</template>
                </h1>
              </div>
            </div>
            <div :class="{ 'px-3': !isCarClarity }">
              <ButtonGroup
                :options="repaymentFrequencyOptions"
                :value="maximumRepaymentFrequency"
                @select="onUpdatePaymentFrequency"
              />
              <div>
                <InputDropdownSliderGroup
                  :min-value="1"
                  :max-value="7"
                  v-model="loanTermYearly"
                  label="Loan term (years)"
                  :has-dropdown="false"
                  :tooltip-message="
                    AFFORDABILITY_TOOLTIP_MESSAGES[selectedApplicant].LOAN_TERM
                  "
                  :infoCircleDark="isCarClarity"
                />
              </div>
            </div>
          </div>
          <div :class="[{ 'cta-container': isCarClarity }]">
            <Button
              class-name="btn-var-primary btn-xlarge w-full mb-2.5"
              @onClick="onSubmit"
            >
              {{ isCarClarity ? 'Apply now' : 'Get my loan options' }}
            </Button>
            <p
              class="text-center text-sm font-medium mb-3"
              v-if="!isCarClarity"
            >
              No impact on your credit score
            </p>
            <div class="cta-access-text-wrapper" v-if="isCarClarity">
              <span class="cta-access-text pt-1 pb-1"
                >Access <span class="font-weight-bold">30+ lenders</span> with
                one easy application</span
              >
              <div class="col-12 col-lg-4 cta-icon-text-wrapper pt-2">
                <img
                  class="cta-access-text-icon"
                  src="@/assets/icons/ui/check_white.png"
                />
                <span class="cta-access-text-with-icon">Expert advice</span>
              </div>
              <div class="col-12 col-lg-4 cta-icon-text-wrapper ml-3 pt-2">
                <img
                  class="cta-access-text-icon"
                  src="@/assets/icons/ui/check_white.png"
                />
                <span class="cta-access-text-with-icon">Competitive rates</span>
              </div>
              <div class="col-12 col-lg-4 cta-icon-text-wrapper ml-3 pt-2">
                <img
                  class="cta-access-text-icon"
                  src="@/assets/icons/ui/check_white.png"
                />
                <span class="cta-access-text-with-icon">Fast approval</span>
              </div>
            </div>
          </div>

          <p
            :class="[
              'text-xs',
              'font-normal',
              'text-grey-darker',
              'pt-3',
              { 'text-disclaimer': isCarClarity }
            ]"
          >
            {{ disclaimer }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownV2 from '@/components/DropdownV2';
import InputDropdownSliderGroup from '@/components/InputDropdownSliderGroup';
import ButtonGroup from '@/components/ButtonGroup';
import Button from '@/components/Button';
import AffordabilityCalculatorService from '@/services/AffordabilityCalculatorService';
import StartMixin from '@/mixins/start.mixin';
import {
  AFFORDABILITY_TOOLTIP_MESSAGES,
  APPLICANTS_OPTION,
  JUST_ME,
  LEAD_APP_URL,
  SEGMENT_WIDGET_CATEGORY,
  SEGMENT_WIDGET_LOAD_EVENTS,
  SEGMENT_WIDGET_EVENTS,
  CREDIT_HISTORY_INTEREST_RATES
} from '@/constants';
import LoadingScreen from '../components/LoadingScreen.vue';

const NUMBER_OF_DEPENDENDS_OPTION = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 }
];

const AFFORDABILITY_CALCULATOR_PAYMENT_FREQUENCY = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Fortnightly', value: 'fortnightly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' }
];

const AFFORDABILITY_CREDIT_HISTORY_OPTIONS = Object.keys(
  CREDIT_HISTORY_INTEREST_RATES
).map(key => ({
  label: key,
  value: CREDIT_HISTORY_INTEREST_RATES[key] * 100
}));

export default {
  name: 'AffordabilityCalculator',
  mixins: [StartMixin],
  components: {
    DropdownV2,
    InputDropdownSliderGroup,
    ButtonGroup,
    Button,
    LoadingScreen
  },
  computed: {
    maximumMonthlyRepayment() {
      const monthlyIncome = AffordabilityCalculatorService.calculateMonthlyIncome(
        {
          netIncome: this.netIncome,
          netIncomeFrequency: this.netIncomeFrequency,
          otherIncome: this.otherIncome,
          otherIncomeFrequency: this.otherIncomeFrequency
        }
      );
      const monthlyExpenses = AffordabilityCalculatorService.calculateMonthlyExpenses(
        {
          whoIsApplying: this.selectedApplicant,
          numberOfDependents: this.numberOfDependents,
          generalLivingExpenses: this.generalLivingExpenses,
          generalLivingExpensesFrequency: this.generalLivingExpensesFrequency,
          mortgagePayments: this.mortgagePayments,
          mortgagePaymentsFrequency: this.mortgagePaymentsFrequency,
          rentPayments: this.rentPayments,
          rentPaymentsFrequency: this.rentPaymentsFrequency,
          otherLoanPayments: this.otherLoanPayments,
          otherLoanPaymentsFrequency: this.otherLoanPaymentsFrequency,
          totalCreditCard: this.totalCreditCard
        }
      );
      const monthlyDisposableIncome = AffordabilityCalculatorService.calculateMonthlyDisposableIncome(
        monthlyIncome,
        monthlyExpenses
      );
      const maximumMonthlyRepayment = AffordabilityCalculatorService.calculateMaximumMonthlyRepayment(
        monthlyDisposableIncome
      );
      return maximumMonthlyRepayment;
    },
    calculateMonthlyDisposableIncome() {
      const monthlyIncome = AffordabilityCalculatorService.calculateMonthlyIncome(
        {
          netIncome: this.netIncome,
          netIncomeFrequency: this.netIncomeFrequency,
          otherIncome: this.otherIncome,
          otherIncomeFrequency: this.otherIncomeFrequency
        }
      );
      const monthlyExpenses = AffordabilityCalculatorService.calculateMonthlyExpenses(
        {
          whoIsApplying: this.selectedApplicant,
          numberOfDependents: this.numberOfDependents,
          generalLivingExpenses: this.generalLivingExpenses,
          generalLivingExpensesFrequency: this.generalLivingExpensesFrequency,
          mortgagePayments: this.mortgagePayments,
          mortgagePaymentsFrequency: this.mortgagePaymentsFrequency,
          rentPayments: this.rentPayments,
          rentPaymentsFrequency: this.rentPaymentsFrequency,
          otherLoanPayments: this.otherLoanPayments,
          otherLoanPaymentsFrequency: this.otherLoanPaymentsFrequency,
          totalCreditCard: this.totalCreditCard
        }
      );
      const monthlyDisposableIncome = AffordabilityCalculatorService.calculateMonthlyDisposableIncome(
        monthlyIncome,
        monthlyExpenses
      );
      return monthlyDisposableIncome;
    },
    maximumMonthlyRepaymentView() {
      let repayment = this.maximumMonthlyRepayment;
      switch (this.maximumRepaymentFrequency) {
        case 'weekly':
          repayment = (repayment * 12) / 52;
          break;
        case 'fortnightly':
          repayment = ((repayment * 12) / 52) * 2;
          break;
        case 'yearly':
          repayment = repayment * 12;
          break;
        default:
          break;
      }
      if (repayment > 0) {
        return repayment;
      }
      return 0;
    },
    maximumLoanAmount() {
      const annuityFactor = AffordabilityCalculatorService.calculateAnnuityFactor(
        {
          interestRatePM: AffordabilityCalculatorService.convertPerAnnumToPerMonth(
            this.interestRate / 100
          ),
          loanTermInMonths: AffordabilityCalculatorService.convertLoanTermYearToMonths(
            this.loanTermYearly
          )
        }
      );
      // AffordabilityCalculatorService.test();
      const maximumLoanAmount = this.maximumMonthlyRepayment * annuityFactor;
      if (maximumLoanAmount > 0) {
        return maximumLoanAmount;
      }
      return 0;
    },
    repaymentFrequencyOptions() {
      return AFFORDABILITY_CALCULATOR_PAYMENT_FREQUENCY.filter(
        item => item.value != 'yearly'
      );
    }
  },
  data: () => {
    let urlParams = new URLSearchParams(window.location.search);
    const partnerLogoUrl = urlParams.get('partnerLogoUrl');
    let disclaimer = urlParams.get('disclaimer');
    if (!disclaimer) {
      disclaimer = `Calculations made using this calculator are intended as a guide and are for illustrative purposes only, and based on the information you provide. Indicative repayments exclude lender fees and charges.`;
    }
    const utmSource = urlParams.get('utm_source');
    const partnerName = urlParams.get('partnerName');

    const defaultInterestRate =
      CREDIT_HISTORY_INTEREST_RATES['Excellent'] * 100;

    return {
      AFFORDABILITY_TOOLTIP_MESSAGES,
      APPLICANTS_OPTION,
      AFFORDABILITY_CALCULATOR_PAYMENT_FREQUENCY,
      NUMBER_OF_DEPENDENDS_OPTION,
      AFFORDABILITY_CREDIT_HISTORY_OPTIONS,
      selectedApplicant: JUST_ME,
      numberOfDependents: '',
      paymentFrequency: 52,
      netIncome: 0,
      netIncomeFrequency: 'monthly',
      otherIncome: 0,
      otherIncomeFrequency: 'monthly',
      generalLivingExpenses: 0,
      generalLivingExpensesFrequency: 'monthly',
      mortgagePayments: 0,
      mortgagePaymentsFrequency: 'monthly',
      rentPayments: 0,
      rentPaymentsFrequency: 'monthly',
      otherLoanPayments: 0,
      otherLoanPaymentsFrequency: 'monthly',
      totalCreditCard: 0,
      creditScore: defaultInterestRate,
      interestRate: defaultInterestRate,
      loanTermYearly: 5,
      maximumRepaymentFrequency: 'weekly',
      isRedirecting: false,
      partnerLogoUrl,
      partnerName: partnerName || utmSource,
      disclaimer,
      hasTriggeredBlur: false,
      hasSubmitted: false,
      isValid: false,
      isCarClarity: false
    };
  },
  mounted() {
    const { googleTagManagerContainerId, is_car_clarity } = this.$route.query;
    this.isCarClarity = !!is_car_clarity;
    if (googleTagManagerContainerId) {
      this.attachGTMScript(googleTagManagerContainerId);
    }
    if (is_car_clarity) {
      this.disclaimer = `Calculations made using this calculator are intended as a guide and are for illustrative purposes only, and are based on the information you provide. Indicative repayments exclude lender fees and charges.`;
    }
    window.analytics.page(
      SEGMENT_WIDGET_CATEGORY,
      SEGMENT_WIDGET_LOAD_EVENTS.AFFORDABILITY_CALCULATOR,
      JSON.parse(JSON.stringify(this.mx$marketingData))
    );
    setTimeout(this.sendBodyHeightToParent, 500);
    this.onElementHeightChange(
      document.body,
      function() {
        this.sendBodyHeightToParent();
      }.bind(this)
    );
  },
  methods: {
    onUpdatePaymentFrequency(value) {
      this.maximumRepaymentFrequency = value;
    },
    onSubmit() {
      const scrollable = document.getElementById('scrollable');
      scrollable.scrollTop = 0;
      this.hasSubmitted = true;

      if (
        !(this.netIncome <= 0 && this.hasSubmitted) &&
        !(this.calculateMonthlyDisposableIncome <= 0 && this.hasSubmitted)
      ) {
        this.isRedirecting = true;
        this.isValid = true;
        window.analytics.track(
          SEGMENT_WIDGET_EVENTS.WIDGET_AFFORDABILITY_CALCULATOR_CTA,
          JSON.parse(JSON.stringify(this.mx$marketingData))
        );
        const personalNetMonthlyIncomeGuesstimate = AffordabilityCalculatorService.calculateMonthlyIncome(
          {
            netIncome: this.netIncome,
            netIncomeFrequency: this.netIncomeFrequency,
            otherIncome: this.otherIncome,
            otherIncomeFrequency: this.otherIncomeFrequency
          }
        );
        let customerSuppliedCreditRatingDepiction = '';
        if (this.creditScore) {
          const creditScoreIndex = Object.keys(
            AFFORDABILITY_CREDIT_HISTORY_OPTIONS
          ).find(key => {
            return (
              AFFORDABILITY_CREDIT_HISTORY_OPTIONS[key].value ===
              this.creditScore
            );
          });
          customerSuppliedCreditRatingDepiction =
            AFFORDABILITY_CREDIT_HISTORY_OPTIONS[creditScoreIndex].label;
        }

        let src = `${LEAD_APP_URL}/start-application?isWidget=true&`;
        const redirectUrl = this.appendQueryParams(src, {
          ...this.$route.query,
          personalNetMonthlyIncomeGuesstimate,
          loanAmount: Math.round(this.maximumLoanAmount),
          loanTermYears: this.loanTermYearly,
          balloonPct: 0,
          numberOfDependants: this.numberOfDependents,
          customerSuppliedCreditRatingDepiction,
          mortgagePayments: this.mortgagePayments,
          mortgagePaymentsFrequency: this.mortgagePaymentsFrequency,
          rentPayments: this.rentPayments,
          rentPaymentsFrequency: this.rentPaymentsFrequency,
          otherLoanPayments: this.otherLoanPayments,
          otherLoanPaymentsFrequency: this.otherLoanPaymentsFrequency,
          totalCreditCard: this.totalCreditCard,
          partnerLogoUrl: '',
          partnerRedirectUrl: '',
          primaryColourHexValue: '',
          secondaryColourHexValue: ''
        });

        var isSafari =
          navigator.vendor &&
          navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1;

        if (this.isCarClarity) {
          setTimeout(() => {
            window.top.location.href = redirectUrl;
            this.isRedirecting = false;
            this.onCloseModal(undefined, false);
          }, 1000);
        } else {
          if (isSafari) {
            setTimeout(() => {
              const carClarity = {
                type: 'handleIphoneHandover',
                redirectUrl: redirectUrl
              };
              window.parent.postMessage(carClarity, '*');
            }, 2000);
            window.setTimeout(() => {
              this.isRedirecting = false;
              this.onCloseModal(undefined, false);
            }, 5000);
          } else if (
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1
          ) {
            setTimeout(() => {
              window.open(redirectUrl, '_blank');
              this.isRedirecting = false;
              this.onCloseModal(undefined, false);
            }, 1000);
          } else {
            setTimeout(() => {
              window.open(redirectUrl, '_blank');
              this.isRedirecting = false;
              this.onCloseModal(undefined, false);
            }, 3000);
          }
        }
      }
    },
    appendQueryParams(src, queryParams) {
      for (let key in queryParams) {
        src += key + '=' + queryParams[key] + '&';
      }
      return src;
    },
    onCreditScoreChange(value) {
      this.creditScore = value;
      this.interestRate = value;
    },
    onCloseModal(_, showPopup = true) {
      const { modalPrimaryColourHexValue = '7100DA' } = this.$route.query;
      const carClarity = {
        type: 'handleCloseModal',
        args: {
          colour: modalPrimaryColourHexValue,
          isShowPopup: showPopup
        }
      };
      window.parent.postMessage(carClarity, '*');
    },
    onBlurInput() {
      this.hasTriggeredBlur = true;
    },
    attachGTMScript(gtmId) {
      const gtmScript = document.createElement('script');
      gtmScript.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${gtmId}')`;
      document.head.appendChild(gtmScript);

      // no script
      const noscript = document.createElement('noscript');
      noscript.text = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
      document.body.appendChild(noscript);
    },
    onElementHeightChange(elm, callback) {
      var lastHeight = elm.clientHeight,
        newHeight;

      (function run() {
        newHeight = elm.clientHeight;
        if (lastHeight != newHeight) callback(newHeight);
        lastHeight = newHeight;

        if (elm.onElementHeightChangeTimer)
          clearTimeout(elm.onElementHeightChangeTimer);

        elm.onElementHeightChangeTimer = setTimeout(run, 200);
      })();
    },
    sendBodyHeightToParent() {
      const bodyHeight = this.getBodyHeight();
      console.log('bodyHeight ', bodyHeight);
      window.parent.postMessage(bodyHeight, '*');
    },
    getBodyHeight() {
      return document.body.clientHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.amount {
  font-size: 40px;
  line-height: 60px;
}
.modal-close {
  position: sticky;
  border: none;
  width: 100%;
  height: 40px;
  padding: 16px 20px 6px;
  background-color: rgb(242, 241, 246);
  display: flex;
  border-radius: 0px;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0px;
  right: 0px;
  z-index: 100;
  cursor: pointer;
}
.invalid-message {
  background-color: #f7ad1e1a;
}
.warning-icon {
  float: left;
  padding-right: 5px;
}
.cta-access-text-wrapper {
  background-color: #51b37f;
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 16px 24px;
  margin-top: 14px;
  justify-content: center;
  .cta-access-text {
    position: relative;
    top: 1px;
    width: 100%;
    font-family: Avenir;
    font-weight: 500;
    text-align: center;
    margin-top: 0.1rem;
    color: #fff;
    font-size: 19px;
    > span {
      color: #fff;
      font-weight: 700 !important;
    }
  }
  .cta-icon-text-wrapper {
    display: flex;
    justify-content: center;
    .cta-access-text-with-icon {
      position: relative;
      top: 1px;
      font-family: Avenir;
      font-weight: 700;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
    .cta-access-text-icon {
      width: 11px;
      height: 12px;
      left: 0px;
      top: 3.7px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 4px;
      position: relative;
    }
  }
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.text-disclaimer {
  padding: 1.5rem 0rem;
  font-size: 12px;
  color: $grey-brown;
}
@media (max-width: 576px) {
  .cta-icon-text-wrapper {
    margin-left: 0px;
  }
  .cta-access-text-wrapper {
    flex-direction: column;
  }
  .cta-access-text-wrapper > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }
  .text-disclaimer {
    padding-left: 0.1rem;
  }
}
.m-width {
  max-width: 980px !important;
  margin: auto;
}
</style>

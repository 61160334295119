<template>
  <div
    v-on-clickaway="away"
    class="relative"
    :class="{
      select: isActive,
      'h-full': theme === THEME_GROUP
    }"
  >
    <button
      @click="toggle"
      class="border-none bg-white w-full text-left m-0 flex items-center justify-between focus:bg-opacity-50"
      :class="{
        'px-3': theme === THEME_DEFAULT,
        'px-2.5 bg-primary text-white w-full h-full rounded-r-sm':
          theme === THEME_GROUP
      }"
    >
      <span
        class="font-bold"
        :class="{
          'text-lg text-grey-darker': theme === THEME_DEFAULT,
          'text-xs': theme === THEME_GROUP
        }"
        >{{ getLabel }}</span
      >
      <div
        class="arrow-down"
        :class="{
          'arrow-down--group': theme === THEME_GROUP
        }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            d="M31.406 7.593c-0.396-0.396-0.864-0.594-1.406-0.594h-28c-0.542 0-1.011 0.198-1.407 0.594s-0.594 0.865-0.594 1.407c0 0.542 0.198 1.010 0.594 1.406l14 14c0.396 0.396 0.865 0.594 1.406 0.594s1.011-0.198 1.406-0.594l14-14c0.395-0.396 0.594-0.864 0.594-1.406s-0.199-1.010-0.594-1.407z"
          ></path>
        </svg>
      </div>
    </button>
    <transition name="select-content">
      <div
        v-if="isActive"
        class="select-content-menu absolute w-full shadow-lg bg-white z-10 max-h-48 overflow-auto rounded-sm"
        :class="{
          'top-10 border-t border-grey-lightest-100': theme === THEME_DEFAULT,
          '': theme === THEME_GROUP
        }"
      >
        <template v-for="option in options">
          <button
            :key="option.value"
            @click="() => onUpdate(option.value)"
            class="border-none bg-white w-full text-left hover:bg-grey-lightest focus:bg-grey-lightest"
            :class="{
              'px-3 pb-3 pt-4': theme === THEME_DEFAULT,
              'p-3': theme === THEME_GROUP
            }"
          >
            <span
              :class="{
                'text-lg': theme === THEME_DEFAULT,
                'text-sm': theme === THEME_GROUP
              }"
              >{{ option.label }}</span
            >
          </button>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

const THEME_DEFAULT = 'defualt';
const THEME_GROUP = 'group';

export default {
  mixins: [clickaway],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    theme: {
      type: String,
      default: THEME_DEFAULT,
      validator: value => [THEME_DEFAULT, THEME_GROUP].includes(value)
    }
  },
  data() {
    return {
      THEME_DEFAULT,
      THEME_GROUP,
      isActive: false
    };
  },
  computed: {
    getLabel() {
      if (this.value || this.value === 0) {
        const selectedOption = this.options.find(
          ({ value }) => this.value === value
        );
        if (selectedOption) {
          return selectedOption.label;
        } else {
          this.onUpdate(undefined);
        }
      }
      return this.placeholder;
    }
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    onUpdate(value) {
      this.$emit('input', value);
      this.away();
    },
    away() {
      this.isActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
button:focus {
  outline: none;
}
.select-content-enter-active,
.select-content-leave-active {
  transition: all 0.2s;
}
.select-content-enter,
.select-content-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
.arrow-down svg {
  width: 8px;
  height: 8px;
  fill: var(--color-primary);
}
.arrow-down--group svg {
  fill: white;
}
</style>

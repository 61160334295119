<template>
  <div>
    <div
      class="input--container validated-input-container"
      :class="{ hasFocus }"
    >
      <p v-if="desc" class="ml-4 mb-8">
        {{ desc }}
      </p>
      <div :class="{ borderless, hasFocus }" class="select--container">
        <!-- Sometimes the value is 0 and thus value === 0 is added in line 21 -->
        <div
          ref="select"
          :class="{
            'has-value': value || value === 0,
            hasFocus,
            disabled: disabled || loading
          }"
          :disabled="disabled || loading"
          :tabindex="disabled ? -1 : 0"
          class="select--input"
          @keydown.down.prevent="handleDown"
          @keydown.up.prevent="handleUp"
          @keydown.enter.prevent="handleEnter"
          @blur.stop="setFocus(false)"
          @focus.stop="setFocus(true)"
          @mousedown="mouseFocus"
        >
          <div v-if="$slots.icon" class="select--icon">
            <slot name="icon" />
          </div>
          <div :class="{ noLabel }" class="select--body">
            <label v-if="!noLabel" :for="name">
              {{ label || placeholder }}
            </label>
            <!-- input also has @focus because safari doesn't take programatic focus -->
            <input
              spellcheck="false"
              v-if="typeahead"
              ref="input"
              type="text"
              :name="name"
              class="select--typeahead"
              :class="{ hasFocus }"
              :value="typeaheadValue"
              @input="typeaheadValue = $event.target.value"
              @blur.stop="clearTypeahead"
              @focus.stop="typeaheadFocus(true)"
            />
            <span
              v-if="!typeahead || !hasFocus"
              :class="{ bold, 'has-value': value || value === 0 }"
              class="select--displayName"
            >
              {{ displayValue }}
            </span>
            <font-awesome-icon
              v-if="loading"
              icon="spinner"
              class="spinner spin"
            />
            <img
              svg-inline
              class="angle-down"
              src="@/assets/icons/ui/chevron-down.svg"
            />
          </div>
          <div class="select--dropdown--border-fix"></div>
        </div>
        <div ref="dropdown" class="select--dropdown">
          <li
            v-for="(option, key) in filteredOptions"
            :key="key"
            :class="{
              highlight: key === optionIndex,
              selected: (option.value || option) == selectedValue
            }"
            @mousedown="onMouseDown(option)"
          >
            {{ option && containsLabel(option) ? option.label : option }}
          </li>
          <li v-if="options.length === 0">
            {{ defaultText }}
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    validationRule: {
      type: String,
      default: ''
    },
    validationMessage: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prefilledDisplayValue: {
      type: [String, Number],
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    typeahead: {
      type: Boolean,
      default: false
    },
    defaultText: {
      type: String,
      default: 'No options available'
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    desc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      optionIndex: null,
      hasFocus: false,
      typeaheadValue: '',
      selectedValue: ''
    };
  },
  computed: {
    filteredOptions() {
      if (this.typeahead) {
        return this.options.filter(item => {
          if (this.containsLabel(item)) {
            const labelStr = String(item.label);
            return (
              labelStr
                .toLowerCase()
                .indexOf(this.typeaheadValue.toLowerCase()) !== -1
            );
          }
          return (
            String(item)
              .toLowerCase()
              .indexOf(this.typeaheadValue.toLowerCase()) !== -1
          );
        });
      }
      return this.options;
    },
    displayValue() {
      if (this.prefilledDisplayValue) {
        return this.prefilledDisplayValue;
      }
      const index = this.options.findIndex(
        option => option.value === this.selectedValue
      );
      if (index >= 0) {
        return this.options[index] ? this.options[index].label : '';
      }
      return 'Select';
    }
  },
  watch: {
    typeaheadValue(val) {
      this.selectedValue = val;
      this.$emit('inputChanged', val);
      this.optionIndex = 0;
      if (val) {
        this.$refs.dropdown.style.height = `${Math.min(
          this.filteredOptions.length * 32,
          145
        )}px`;
      }
    },
    value() {
      this.selectedValue = this.value;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.selectedValue = this.value;
    });
  },
  methods: {
    containsLabel(option) {
      return (
        typeof option === 'object' && Object.keys(option).includes('label')
      );
    },
    handleUp() {
      // Handles the up arrow click whenever the select is in focus.
      if (this.optionIndex > 0) {
        this.optionIndex--;
        // handles scrolling when the dropdown is scrollable.
        if (this.$refs.dropdown.scrollTop > this.optionIndex * 32) {
          this.$refs.dropdown.scrollTo(0, this.optionIndex * 32);
        }
        if (this.$refs.dropdown.scrollTop + 160 < this.optionIndex * 32) {
          this.$refs.dropdown.scrollTo(0, this.optionIndex * 32 - 160);
        }
      }
    },
    handleDown() {
      if (this.optionIndex < this.options.length - 1) {
        this.optionIndex++;
        if (this.$refs.dropdown.scrollTop + 160 < this.optionIndex * 32) {
          this.$refs.dropdown.scrollTo(0, this.optionIndex * 32 - 160);
        }
        if (this.$refs.dropdown.scrollTop > this.optionIndex * 32) {
          this.$refs.dropdown.scrollTo(0, this.optionIndex * 32);
        }
      }
    },
    handleEnter() {
      const value = this.filteredOptions[this.optionIndex];
      if (value) {
        const selectedValue = this.containsLabel(value) ? value.value : value;
        this.selectedValue = selectedValue;
        this.$emit('select', selectedValue);
      }
      if (this.typeahead) {
        this.$refs.input.blur();
        this.typeaheadValue = '';
      } else {
        this.$refs.select.blur();
      }
    },
    setFocus(value) {
      if (value) {
        if (this.hasFocus) {
          if (this.typeahead) {
            this.$refs.input.blur();
          } else {
            this.$refs.select.blur();
          }
        } else {
          if (this.typeahead) {
            this.$nextTick(() => this.$refs.input.focus());
          }

          this.$refs.dropdown.style.height = `${Math.max(
            this.$refs.dropdown.scrollHeight,
            145
          )}px`;
          this.hasFocus = value;
        }
      } else {
        this.$refs.dropdown.style.height = '0px';
        this.hasFocus = false;
      }
      // adds a marging of 36 px at the bottom of the page if the distance from the bottom of the page and the dropdown box is less than 50
      const isBottomOfPage =
        window.innerHeight -
          this.$refs.dropdown.getBoundingClientRect().bottom <=
        50;
      this.$refs.dropdown.style.marginBottom = isBottomOfPage ? '36px' : '0px';
    },
    mouseFocus(e) {
      if (this.hasFocus) {
        if (this.typeahead) {
          this.$refs.input.blur();
        } else {
          this.$refs.select.blur();
        }
        e.preventDefault();
      }
      // custom checker for ie 11 as @focus.down is not working for ie 11
      if (window.document.documentMode) {
        if (this.hasFocus) {
          this.setFocus(false);
        } else {
          this.setFocus(true);
        }
      }
    },
    typeaheadFocus(value) {
      if (value !== this.hasFocus) {
        this.setFocus(value);
      }
    },
    clearTypeahead() {
      if (this.hasFocus) {
        this.typeaheadValue = '';
        this.setFocus(false);
      }
    },
    onMouseDown(option) {
      const selectedValue = this.containsLabel(option) ? option.value : option;
      this.selectedValue = selectedValue;
      this.$emit('select', selectedValue);

      this.typeaheadValue = '';
      this.setFocus(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.input--container {
  position: relative;
  min-width: 200px;

  @include respond-to(xs) {
    min-width: 0px;
    margin-bottom: 46px;
  }
  margin-bottom: 16px;
  color: $grey-darker;
  &.hasFocus {
    height: 46px;
    z-index: 11;
  }

  @include respond-to(md) {
    &.hasFocus {
      height: 60px;
    }
  }
}
.error-text {
  display: flex;
  margin-top: 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: color(error);
}
.select--container {
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 10;

  .select--input {
    width: 100%;
    font-size: 14px;
    outline: none;
    position: relative;
    display: flex;
    height: 46px;
    cursor: pointer;

    @include respond-to(md) {
      height: 60px;
    }

    .select--icon {
      height: 100%;
      border-right: 1px solid $grey;
      transition: all 150ms ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey;
      padding: 0px 12px;
      background-color: $white;
      border-radius: $border-radius-v2 0px 0px $border-radius-v2;
    }

    &:hover {
      .select--icon {
        border-color: $grey-darker;
      }
    }

    .select--typeahead {
      width: calc(100% - 24px);
      background-color: transparent;
      overflow: hidden;
      border: 0px;
      position: absolute;
      left: 12px;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      &.hasFocus {
        height: auto;
      }

      &.select--no-height {
        height: 0px;
      }

      @include respond-to(md) {
        height: 60px;
      }
    }

    .select--displayName {
      // position: absolute;
      // left: 23px;
      // right: 23px;
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      color: #606060;

      line-height: 1.73;
      font-family: Avenir-Roman;
      font-size: 15px;

      &.bold {
        font-weight: 600;
      }
    }

    .angle-down {
      transition: 150ms ease-out all;
      margin-left: auto;
      height: 12px;
      width: 13px;
    }

    .spinner {
      margin-left: auto;
      height: 18px;
      color: $grey-dark;
      margin-top: -5px;
    }

    label {
      position: absolute;
      top: 0;
      left: 12px;
      padding-top: 19px;
      height: 56px;
      color: $grey-dark;
      transition: all ease-out 150ms;
      cursor: pointer;
    }

    &.hasFocus {
      .icon-container {
        border-right: 2px solid $secondary;
        color: $primary;
      }

      label {
        padding-top: 10px;
        font-size: 11px;
      }

      .angle-down {
        transform: rotatex(180deg);
      }
      ~ .select--dropdown {
        box-sizing: content-box;
      }

      .select--dropdown--border-fix {
        opacity: 1;
        height: 30px;
      }

      .select--icon {
        border-color: $primary;
      }
    }

    &.has-value {
      label {
        padding-top: 10px;
        font-size: 11px;
      }
    }

    &.error {
      border: 1px solid $error;
      .select--icon {
        border-right: 1px solid $error;
      }
    }
    .select--body {
      width: 100%;
      position: relative;
      // z-index: 2;
      padding: 19px 30px 17px;
      border: 2px solid $grey-lightest;
      border-radius: $border-radius-v2;
      height: 100%;
      max-height: 35px;
      display: flex;
      text-align: center;
      align-items: center;
      background: $white;
      &.noLabel {
        padding-top: 0px;
        align-items: center;
        .spinner,
        .angle-down {
          margin-top: 0px;
        }
      }
    }
  }

  .select--dropdown--border-fix {
    border-left: 2px solid $grey-lightest;
    border-right: 2px solid $grey-lightest;
    height: 0;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: -2px;
    position: absolute;
  }

  .select--dropdown {
    height: 0px;
    overflow-y: scroll;
    border-top: 0px;
    border-radius: 0px 0px $border-radius-v2 $border-radius-v2;
    list-style: none;
    transition: background-color ease-out 200ms, height ease-in-out 100ms;
    z-index: 10;
    background-color: $white;
    color: #606060;
    position: absolute;
    left: 0;
    right: 0;
    top: 150%;
    overflow: auto;

    @include respond-to(md) {
      top: 120%;
    }

    .hasFocus & {
      border: 2px solid $grey-lightest;
    }
    li {
      transition: background-color ease-out 200ms;
      line-height: 40px;
      padding-right: 13px;
      padding: 5px 30px;
      font-size: 1rem;
      text-align: left;
      // border-bottom: 2px solid $grey-lightest;
      list-style: none;
      // line-height: 1.73;
      font-family: Avenir-Roman;
      font-size: 15px;
      &:last-of-type {
        border-bottom: none;
      }
      &:hover,
      &.highlight {
        background-color: $grey-lightest;
      }
    }
  }

  .disabled {
    opacity: 0.5;
    background: $grey-lighter;
    pointer-events: none;
  }

  &.borderless {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
      0 1px 3px 0 rgba(0, 0, 0, 0.2);
    .select--dropdown {
      border: none;
    }
    .select--input {
      border: none;
    }
  }
}
.alert--error {
  fill: $error;
  height: 16px;
  width: 16px;
  margin-right: 6px;
}
</style>

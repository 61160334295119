<template>
  <div>
    <div
      class="mx-auto flex flex-wrap justify-center gap-x-2.5"
      :class="{
        'justify-end': widgetVariant === CALCULATOR_VARIANT_2,
        'md:justify-center': widgetVariant === CALCULATOR_VARIANT_3
      }"
    >
      <button
        class="cc-button cursor-pointer mt-2.5 xs:mt-0 py-2 px-5
         md:mb-0
        "
        :class="{
          'cc-button-fill bg-primary text-white mb-2': [
            CALCULATOR_VARIANT_1,
            CALCULATOR_VARIANT_3
          ].includes(widgetVariant),
          'cc-button-outline mb-2.5': widgetVariant === CALCULATOR_VARIANT_2,
          'flex-grow': [CALCULATOR_VARIANT_1, CALCULATOR_VARIANT_2].includes(
            widgetVariant
          )
        }"
        @click="handleClickCalculateMyRepayments"
      >
        <span class="cc-button-label cc-widget-font">
          Calculate my repayments
        </span>
      </button>
      <button
        class="cc-button cursor-pointer xs:mt-0 py-2 px-5"
        :class="{
          'md:ml-2.5 cc-button-outline ml-0 mt-2.5': [
            CALCULATOR_VARIANT_1,
            CALCULATOR_VARIANT_3
          ].includes(widgetVariant),
          'cc-button-fill bg-primary text-white md:mr-2.5 md:ml-2.5 mt-2':
            widgetVariant === CALCULATOR_VARIANT_2,
          'flex-grow': [CALCULATOR_VARIANT_1, CALCULATOR_VARIANT_2].includes(
            widgetVariant
          )
        }"
        @click="handleClickHowMuchCanIBorrow"
      >
        <span class="cc-button-label cc-widget-font">
          How much I can borrow?
        </span>
      </button>
    </div>
    <!--TODO use query params-->
    <a class="cursor-pointer block">
      <div
        class="mt-5 md:mt-3 flex items-center justify-center
        md:max-w-sm	
        ml-0
        "
        :class="{
          'flex justify-center': widgetVariant === CALCULATOR_VARIANT_3,
          'md:justify-start': widgetVariant !== CALCULATOR_VARIANT_3,
          'md:mx-auto': widgetVariant === CALCULATOR_VARIANT_3
        }"
      >
        <p class="block text-right text-xs text-grey-dark mr-2 powered-by">
          Powered by
        </p>
        <img
          @click="onLogoClick"
          svg-inline
          src="@/assets/images/logo/CarClarity.svg"
          class="h-6 cursor-pointer"
        />
      </div>
    </a>
  </div>
</template>

<script>
import {
  SEGMENT_WIDGET_EVENTS,
  SEGMENT_WIDGET_CATEGORY,
  SEGMENT_WIDGET_LOAD_EVENTS,
  CALCULATOR_VARIANT_1,
  CALCULATOR_VARIANT_2,
  CALCULATOR_VARIANT_3,
  CALCULATOR_VARIANTS
} from '@/constants';
import ApplicationMixin from '@/mixins/application.mixin';
import StartMixin from '@/mixins/start.mixin';

export default {
  mixins: [StartMixin, ApplicationMixin],
  data() {
    return {
      CALCULATOR_VARIANT_1,
      CALCULATOR_VARIANT_2,
      CALCULATOR_VARIANT_3,
      widgetVariant: CALCULATOR_VARIANT_1
    };
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    const { googleTagManagerContainerId } = this.$route.query;
    if (googleTagManagerContainerId) {
      this.attachGTMScript(googleTagManagerContainerId);
    }
    window.analytics.page(
      SEGMENT_WIDGET_CATEGORY,
      SEGMENT_WIDGET_LOAD_EVENTS.CALCULATOR,
      JSON.parse(JSON.stringify(this.mx$marketingData))
    );
    this.mx$addFontStyles();

    const variant = urlParams.get('widgetVariant');
    if (variant && CALCULATOR_VARIANTS.includes(variant)) {
      this.widgetVariant = variant;
    }
  },
  methods: {
    handleClickCalculateMyRepayments() {
      window.analytics.track(
        SEGMENT_WIDGET_EVENTS.WIDGET_CALCULATOR_CALCULATE_MY_REPAYMENTS,
        JSON.parse(JSON.stringify(this.mx$marketingData))
      );
      const carClarity = {
        type: 'handleOpenLoanCalculator',
        ...this.$route.query
      };
      window.parent.postMessage(carClarity, '*');
    },
    handleClickHowMuchCanIBorrow() {
      window.analytics.track(
        SEGMENT_WIDGET_EVENTS.WIDGET_CALCULATOR_HOW_MUCH_CAN_I_BORROW,
        JSON.parse(JSON.stringify(this.mx$marketingData))
      );
      const carClarity = {
        type: 'handleOpenAffordabilityCalculator',
        ...this.$route.query
      };
      window.parent.postMessage(carClarity, '*');
    },
    onLogoClick() {
      window.analytics.track(
        SEGMENT_WIDGET_EVENTS.WIDGET_LINK_LOGO,
        JSON.parse(JSON.stringify(this.mx$marketingData))
      );
      let homePageUrl = 'https://www.carclarity.com.au/';
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case 'development':
          homePageUrl = 'http://localhost:8080/';
          break;
        case 'qa':
        case 'staging':
          homePageUrl = 'https://carclarity-website.webflow.io/';
          break;
        default:
          break;
      }
      const partnerId = '1d6bf09fe0af4ffa92c72cf6e0cad0c2';
      window.open(`${homePageUrl}?partner_id=${partnerId}`);
    },
    attachGTMScript(gtmId) {
      const gtmScript = document.createElement('script');
      gtmScript.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${gtmId}')`;
      document.head.appendChild(gtmScript);

      // no script
      const noscript = document.createElement('noscript');
      noscript.text = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
      document.body.appendChild(noscript);
    }
  }
};
</script>

<style lang="scss" scoped>
.cc-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 41px;
  border-radius: 4px;
  &.cc-button-fill {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  &.cc-button-outline {
    border: 1px solid #d5d5d5;
    color: #999999;
  }
}
.cc-logo-center {
  margin-left: 125px;
}

@media only screen and (device-width: 375px) {
  .cc-logo-center {
    margin-left: 0;
  }
}

.powered-by {
  margin-top: 2px;
}
$margin-settings-1: 90px;
$margin-settings-2: 80px;
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .cc-logo-center {
    margin-left: $margin-settings-1;
  }
  .cc-center {
    margin-left: $margin-settings-1;
  }
}
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .cc-logo-center {
    margin-left: $margin-settings-1;
  }
  .cc-center {
    margin-left: $margin-settings-1;
  }
}
@media only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .cc-logo-center {
    margin-left: $margin-settings-1;
  }
  .cc-center {
    margin-left: $margin-settings-1;
  }
}
@media only screen and (device-width: 360px) and (device-height: 740px) {
  .cc-logo-center {
    margin-left: $margin-settings-2;
  }
  .cc-center {
    margin-left: $margin-settings-2;
  }
}
@media only screen and (device-width: 360px) and (device-height: 800px) {
  .cc-logo-center {
    margin-left: $margin-settings-2;
  }
  .cc-center {
    margin-left: $margin-settings-2;
  }
}
</style>

<style>
body {
  background-color: transparent;
}
</style>

import { VEHICLE_REQUEST_GROUP_MARKETING_PROPERTY_MAPPING } from '@/constants/misc';

export default {
  data() {
    return {
      mx$marketingData: {
        source: 'partner'
      }
    };
  },
  mounted() {
    this.extractAndUpdateMarketingData();
  },
  methods: {
    extractAndUpdateMarketingData() {
      if (window.BRANCH) {
        this.mx$marketingData.featureBranch = window.BRANCH;
      }
      this.updateMarketingDataFromCookies(
        this.$cookies,
        VEHICLE_REQUEST_GROUP_MARKETING_PROPERTY_MAPPING
      );
      try {
        for (const [key, value] of new URLSearchParams(
          window.location.search
        )) {
          if (VEHICLE_REQUEST_GROUP_MARKETING_PROPERTY_MAPPING[key]) {
            this.mx$marketingData[key] = value;
          }
        }
      } catch (error) {
        console.error('Error storing marketing data property from url', error);
      }
    },
    updateMarketingDataFromCookies(source, propertiesMap) {
      Object.entries(propertiesMap).forEach(([key, value]) => {
        const unencodedValue = source.get(key);
        if (unencodedValue) {
          try {
            this.mx$marketingData[value] = unencodedValue;
          } catch (error) {
            console.error(
              `Error storing marketing data property from cookie for property ${value}: ${unencodedValue} (unencoded value)`,
              error
            );
          }
        }
      });
    }
  }
};

const PAYMENT_TYPE_IN_ARREARS = 0;
const PAYMENT_TYPE_IN_ADVANCE = 1;

export default {
  periodicPayment(
    rate,
    present,
    years,
    frequency,
    future = 0,
    type = PAYMENT_TYPE_IN_ARREARS
  ) {
    return this.PMT(
      this.calculateRate(rate, frequency),
      this.calculatePeriods(years, frequency),
      present,
      future,
      type
    );
  },
  PMT(rate, periods, present, future = 0, type = PAYMENT_TYPE_IN_ARREARS) {
    if (rate === 0) {
      return -((present + future) / periods);
    }
    const term = Math.pow(1 + rate, periods);
    return type === PAYMENT_TYPE_IN_ADVANCE
      ? -(
          ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
          (1 + rate)
        )
      : -((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term));
  },

  calculatePeriods(years, paymentFrequency) {
    return Math.floor(years * paymentFrequency);
  },
  calculateRate(rate, paymentFrequency) {
    return rate / paymentFrequency;
  }
};

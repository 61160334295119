import { CREDIT_HISTORY_INTEREST_RATES } from '@/constants/misc';

const MONTHLY_FACTOR = {
  weekly: 30 / 7,
  fortnightly: 30 / 14,
  monthly: 1,
  yearly: 1 / 12
};

const MINIMUM_EXPENSES_JUST_ME = 1200;
const MINIMUM_EXPENSES_TWO_OF_US = 2300;
const NUMBER_OF_DEPENDENTS_MULTIPLIER = 300;
const WHO_IS_APPLYING_JUST_ME = 'just-me';
// const WHO_IS_APPLYING_TWO_OF_US = 'two of us';
const CREDIT_CARD_EXPENSE_LIMIT_RATE = 0.07;
const MINIMUM_DSCR = 1.75;

export default {
  mapToMonthlyFactor(frequency) {
    if (!frequency) {
      return 0;
    }
    return MONTHLY_FACTOR[frequency];
  },
  calculateMonthlyIncome({
    netIncome,
    netIncomeFrequency,
    otherIncome,
    otherIncomeFrequency
  }) {
    return (
      netIncome * this.mapToMonthlyFactor(netIncomeFrequency) +
      otherIncome * this.mapToMonthlyFactor(otherIncomeFrequency)
    );
  },
  calculateMinimumExpenses(whoIsApplying, numberOfDependents = 0) {
    if (whoIsApplying === WHO_IS_APPLYING_JUST_ME) {
      return (
        MINIMUM_EXPENSES_JUST_ME +
        numberOfDependents * NUMBER_OF_DEPENDENTS_MULTIPLIER
      );
    }
    return (
      MINIMUM_EXPENSES_TWO_OF_US +
      numberOfDependents * NUMBER_OF_DEPENDENTS_MULTIPLIER
    );
  },
  calculateMonthlyExpenses({
    whoIsApplying,
    numberOfDependents = 0,
    generalLivingExpenses = 0,
    generalLivingExpensesFrequency,
    mortgagePayments = 0,
    mortgagePaymentsFrequency,
    rentPayments = 0,
    rentPaymentsFrequency,
    otherLoanPayments = 0,
    otherLoanPaymentsFrequency,
    totalCreditCard = 0
  }) {
    const computedGeneralLivingExpenses = Math.max(
      this.calculateMinimumExpenses(whoIsApplying, numberOfDependents),
      generalLivingExpenses *
        this.mapToMonthlyFactor(generalLivingExpensesFrequency)
    );

    const computedMortgagePayments =
      mortgagePayments * this.mapToMonthlyFactor(mortgagePaymentsFrequency);
    const computedRentPayments =
      rentPayments * this.mapToMonthlyFactor(rentPaymentsFrequency);
    const computedLoanPayments =
      otherLoanPayments * this.mapToMonthlyFactor(otherLoanPaymentsFrequency);
    const computedTotalCreditCardLimit =
      totalCreditCard * CREDIT_CARD_EXPENSE_LIMIT_RATE;

    const totalMonthlyExpenses =
      computedGeneralLivingExpenses +
      computedMortgagePayments +
      computedRentPayments +
      computedLoanPayments +
      computedTotalCreditCardLimit;
    return totalMonthlyExpenses;
  },
  calculateMonthlyDisposableIncome(monthlyIncome, monthlyExpenses) {
    return monthlyIncome - monthlyExpenses;
  },
  calculateMaximumMonthlyRepayment(
    monthlyDisposableIncome,
    minimumDSCR = MINIMUM_DSCR
  ) {
    return monthlyDisposableIncome / minimumDSCR;
  },
  convertPerAnnumToPerMonth(interestRate) {
    return interestRate / 12;
  },
  convertLoanTermYearToMonths(loanTermYear) {
    return loanTermYear * 12;
  },
  calculateAnnuityItem(interestRatePM, t) {
    return 1 / Math.pow(1 + interestRatePM, t);
  },
  calculateAnnuityFactor({
    month = 1,
    totalAnnuityInMonth = 0,
    interestRatePM,
    loanTermInMonths
  }) {
    const annuityInMonth = this.calculateAnnuityItem(interestRatePM, month);
    if (month <= loanTermInMonths) {
      return this.calculateAnnuityFactor({
        month: ++month,
        totalAnnuityInMonth: totalAnnuityInMonth + annuityInMonth,
        interestRatePM,
        loanTermInMonths
      });
    } else {
      return totalAnnuityInMonth;
    }
  },

  test() {
    const monthlyIncome = this.calculateMonthlyIncome({
      netIncome: 1750,
      netIncomeFrequency: 'weekly',
      otherIncome: 450,
      otherIncomeFrequency: 'weekly'
    });
    const monthlyExpenses = this.calculateMonthlyExpenses({
      whoIsApplying: 'just me',
      numberOfDependents: 3,
      generalLivingExpenses: 300,
      generalLivingExpensesFrequency: 'weekly',
      mortgagePayments: 500,
      mortgagePaymentsFrequency: 'weekly',
      otherLoanPayments: 150,
      otherLoanPaymentsFrequency: 'weekly',
      totalCreditCard: 10000
    });

    const monthlyDisposableIncome = this.calculateMonthlyDisposableIncome(
      monthlyIncome,
      monthlyExpenses
    );
    // console.log('monthlyh dispoable income', monthlyDisposableIncome);
    const maximumMonthlyRepayment = this.calculateMaximumMonthlyRepayment(
      monthlyDisposableIncome
    );
    const loanTerm = 2;
    const ratePa = CREDIT_HISTORY_INTEREST_RATES['Excellent'];

    console.log('monthly income', monthlyIncome);
    console.log('monthly expenses', monthlyExpenses);
    console.log('interest rate', this.convertPerAnnumToPerMonth(ratePa));
    console.log('## anuity factor');
    const af = this.calculateAnnuityFactor({
      interestRatePM: this.convertPerAnnumToPerMonth(ratePa),
      loanTermInMonths: this.convertLoanTermYearToMonths(loanTerm)
    });
    console.log('max loan amount', maximumMonthlyRepayment * af);
  }
};

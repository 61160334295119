<template>
  <div
    :class="[
      ddContainerClass
        ? ddContainerClass
        : 'py-3.5 bg-white mb-2 w-full flex flex-col justify-between rounded-sm'
    ]"
  >
    <div class="flex items-center mb-2 px-3">
      <p
        :class="[
          infoCircleDark ? 'text-black' : 'text-grey-darker',
          'text-sm font-bold whitespace-nowrap mr-2'
        ]"
      >
        {{ label }}
      </p>
      <v-popover v-if="tooltipMessage" offset="0" class="popover v-popover">
        <font-awesome-icon
          icon="info-circle"
          :class="[{ 'text-dark': infoCircleDark }, 'info-circle', 'text-xs']"
        />
        <tooltip
          slot="popover"
          :tip-text="tooltipMessage"
          direction="up"
          :tooltip-styles="{
            maxWidth: '200px',
            backgroundColor: '#505050',
            zIndex: 1000
          }"
          :dismiss="true"
        />
      </v-popover>
    </div>
    <Select
      :value="value"
      @input="onUpdate"
      :options="options"
      :placeholder="placeholder"
    ></Select>
  </div>
</template>

<script>
import Select from '@/components/Select';
import Tooltip from '@/components/Tooltip';

export default {
  components: {
    Select,
    Tooltip
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    tooltipMessage: {
      type: String,
      default: ''
    },
    infoCircleDark: { type: Boolean, default: false },
    ddContainerClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    onUpdate(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.popover {
  display: flex;
  margin-bottom: 3px;
  &__inner {
    font-size: 0.8rem;
    background: #505050;
    color: white;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
    max-width: 250px;
  }
  ::v-deep.trigger {
    display: flex !important;
  }
  .arrow {
    background-color: #505050;
  }
  .tooltip-arrow {
    border-color: #505050;
  }
}
.info-circle {
  color: #b0b0b0;
}
.text-dark {
  color: #000000 !important;
}
.select::before {
  left: 0;
}
</style>

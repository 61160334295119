<template>
  <div class="bg-white">
    <div
      id="repayments-container "
      class="flex flex-col md:flex-row justify-around items-center sm:bg-white h-screen relative"
    >
      <div
        class="hidden text-white text-center p-4.2 md:flex flex-col self-stretch justify-between items-between left-side md:w-1/2"
        v-bind:style="{ backgroundColor: '#' + secondaryColourHexValue }"
      >
        <div class="mt-8">
          <h1 class="text-base-2 font-bold text-left leading-10">
            Get a better car loan.<br />
            Hassle free.
          </h1>

          <div class="flex items-start mt-8">
            <div class="h-11 w-11 mr-2.5">
              <img src="@/assets/icons/ui/check.svg" />
            </div>
            <div class="text-left">
              <h5 class="text-base font-bold mb-1">
                Compare 30+ lenders
              </h5>
              <p class="text-sm font-normal">
                Our smart loan matching technology accesses over 30 of
                Australia's top lenders to find you the best car loan with one
                easy application.
              </p>
            </div>
          </div>

          <div class="flex items-start mt-8">
            <div class="h-11 w-11 mr-2.5">
              <img src="@/assets/icons/ui/check.svg" />
            </div>
            <div class="text-left">
              <h5 class="text-base font-bold mb-1">
                Get matches in 60 seconds
              </h5>
              <p class="text-sm font-normal">
                Our easy-to-use platform will guide you through the simple
                application and find your personalised matches in no time.
              </p>
            </div>
          </div>

          <div class="flex items-start mt-8">
            <div class="h-11 w-11 mr-2.5">
              <img src="@/assets/icons/ui/check.svg" />
            </div>
            <div class="text-left">
              <h5 class="text-base font-bold mb-1">
                No impact on your credit score
              </h5>
              <p class="text-sm font-normal">
                Our soft credit check will match and pre-qualify you for loans
                without affecting your credit score.
              </p>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-end">
          <div>
            <p class="text-xs text-left pb-2 font-medium">Powered by</p>
            <img class="cc-logo" src="@/assets/icons/ui/cc-logo-white.svg" />
          </div>
          <div>
            <img
              class="cc-overlay"
              src="@/assets/icons/ui/cc-logo-overlay.svg"
            />
          </div>
        </div>
      </div>
      <div class="bg-white w-full md:w-1/2">
        <!-- src="https://www.carclarity.com.au/lead-application/application-details-3?isWidget=true" -->

        <iframe
          :src="widgetSrc"
          frame-border="0"
          min-height="400px"
          class="application-details w-full h-screen"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const { secondaryColourHexValue = '7100DA' } = this.$route.query;
    this.secondaryColourHexValue = secondaryColourHexValue;
  },
  computed: {
    widgetSrc() {
      const { widgetType } = this.$route.query;
      let src = this.getWidgetSrc(widgetType);
      if (Object.keys(this.$route.query).length) {
        return this.appendQueryParams(src, this.$route.query);
      }
      return src;
    }
  },
  data() {
    return {
      secondaryColourHexValue: '7100DA'
    };
  },
  methods: {
    onGetOptionsClick() {
      const carClarity = {
        type: 'getOptionClicked',
        ...this.$route.query
      };
      window.parent.postMessage(carClarity, '*');
    },
    appendQueryParams(src, queryParams) {
      for (let key in queryParams) {
        src += key + '=' + queryParams[key] + '&';
      }
      return src;
    },
    getWidgetSrc() {
      return 'https://testing-widget--carclarity.netlify.app/start-application?isWidget=true';
    }
  }
};
</script>

<style hlang="scss">
body {
  background-color: #fff;
}
.application-details {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}
@media only screen and (max-width: 375px) {
  .application-details {
    min-height: 556px;
  }
}

@media only screen and (width: 360px) and (max-height: 640px) {
  .application-details {
    min-height: 600px;
  }
}

@media only screen and (min-width: 720px) {
  .application-details {
    position: initial;
    top: initial;
    left: initial;
    bottom: initial;
  }
}

@media only screen and (max-width: 800px) {
  #repayments-container {
    background: #fff !important;
  }
}

#repayments-container .left-side {
  min-width: 350px;
}
.cc-logo {
  width: 134px;
  height: 39px;
}
.cc-overlay {
  width: 122px;
  height: 119px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col tablet:flex-row justify-between bg-white py-3.5 px-3 mb-2 rounded-sm"},[_c('div',{staticClass:"flex-1 flex flex-col justify-center tablet:mr-5"},[_c('div',{staticClass:"flex items-center"},[_c('p',{class:[
          _vm.infoCircleDark ? 'text-black' : 'text-grey-darker',
          'text-sm font-bold'
        ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.tooltipMessage)?_c('v-popover',{staticClass:"ml-2 popover v-popover",attrs:{"offset":"0"}},[_c('font-awesome-icon',{class:[{ 'text-dark': _vm.infoCircleDark }, 'info-circle', 'text-xs'],attrs:{"icon":"info-circle"}}),_c('tooltip',{attrs:{"slot":"popover","tip-text":_vm.tooltipMessage,"direction":"up","tooltip-styles":{
            maxWidth: '200px',
            backgroundColor: '#505050',
            zIndex: 1000
          },"dismiss":true},slot:"popover"})],1):_vm._e()],1),(_vm.hasSlider)?_c('RangeSlider',{attrs:{"value":_vm.selectedValue,"minValue":_vm.minValue,"maxValue":_vm.maxValue,"step":1},on:{"input":_vm.onUpdateRange}}):_vm._e()],1),_c('div',{staticClass:"flex mt-3.5 tablet:mt-0 group-container tablet:justify-end"},[_c('vue-numeric',{staticClass:"border border-solid border-primary p-2 text-lg text-center custom-input rounded-l-sm",class:{
        'rounded-r-none': _vm.hasDropdown,
        'rounded-r-sm': !_vm.hasDropdown,
        'text-grey-darker': _vm.infoCircleDark
      },attrs:{"min":_vm.minValue,"max":_vm.maxValue,"precision":_vm.precision},on:{"input":_vm.onUpdateRange},nativeOn:{"keypress":function($event){return _vm.onKeyPress($event)},"blur":function($event){return _vm.onBlur($event)}},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}),(_vm.hasDropdown)?_c('div',{staticClass:"custom-dropdown h-full"},[_c('Select',{attrs:{"options":_vm.options,"value":_vm.dropdown,"theme":"group"},on:{"input":_vm.onUpdateDropdown}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="bg-transparent" v-bind:class="containerAlignment">
    <button
      style="background-color: #000e82"
      class="
        cc-button cc-button-fill
        cursor-pointer
        bg-primary
        text-white
        py-2
        px-5
      "
      @click="onGetMyLoanOptionsClick"
    >
      <span class="cc-button-label cc-widget-font">Get my loan options</span>
    </button>
    <div class="mt-5 flex items-center">
      <p class="block text-right text-xs text-grey-dark mr-2 mt-1">
        Powered by
      </p>
      <img
        @click="onLogoClick"
        svg-inline
        src="@/assets/images/logo/CarClarity.svg"
        class="h-6 cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
import {
  SEGMENT_WIDGET_EVENTS,
  SEGMENT_WIDGET_CATEGORY,
  SEGMENT_WIDGET_LOAD_EVENTS,
  LEAD_APP_URL
} from '@/constants';
import ApplicationMixin from '@/mixins/application.mixin';
import StartMixin from '@/mixins/start.mixin';

export default {
  mixins: [StartMixin, ApplicationMixin],
  mounted() {
    window.analytics.page(
      SEGMENT_WIDGET_CATEGORY,
      SEGMENT_WIDGET_LOAD_EVENTS.JOURNEY_FINANCE,
      JSON.parse(JSON.stringify(this.mx$marketingData))
    );
    const { secondaryColourHexValue = '7100DA' } = this.$route.query;
    this.secondaryColourHexValue = secondaryColourHexValue;
    this.mx$addFontStyles();
  },
  computed: {
    containerAlignment() {
      const { isCenterAligned = 'false' } = this.$route.query;
      if (isCenterAligned === 'true') {
        return {
          flex: true,
          'flex-col': true,
          'items-center': true,
          'justify-center': true,
          'mx-auto': true
        };
      }
      return {};
    }
  },
  methods: {
    onGetMyLoanOptionsClick() {
      window.analytics.track(
        SEGMENT_WIDGET_EVENTS.WIDGET_JOURNEY_FINANCE_GET_MY_LOAN_OPTIONS,
        JSON.parse(JSON.stringify(this.mx$marketingData))
      );
      let src = `${LEAD_APP_URL}?isWidget=true&`;
      window.open(
        this.mx$appendQueryParams(src, {
          ...this.$route.query,
          partnerLogoUrl: '',
          partnerRedirectUrl: '',
          primaryColourHexValue: '',
          secondaryColourHexValue: ''
        })
      );
    },
    onLogoClick() {
      window.analytics.track(
        SEGMENT_WIDGET_EVENTS.WIDGET_LINK_LOGO,
        JSON.parse(JSON.stringify(this.mx$marketingData))
      );
      let homePageUrl = 'https://www.carclarity.com.au/';
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case 'development':
          homePageUrl = 'http://localhost:8080/';
          break;
        case 'qa':
        case 'staging':
          homePageUrl = 'https://carclarity-website.webflow.io/';
          break;
        default:
          break;
      }
      const partnerId = '1d6bf09fe0af4ffa92c72cf6e0cad0c2';
      window.open(`${homePageUrl}?partner_id=${partnerId}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.cc-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 41px;
  border-radius: 4px;
  &.cc-button-fill {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  &.cc-button-outline {
    border: 1px solid #d5d5d5;
    color: #999999;
  }
}
</style>

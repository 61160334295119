<template>
  <div
    class="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-white z-110"
  >
    <div class="flex flex-col items-center px-5">
      <div class="mb-6 flex items-center">
        <div>
          <img class="h-6 md:h-7" :src="partnerLogo" :alt="partnerName" />
        </div>
        <div class="mx-4">
          <img class="w-5" src="@/assets/icons/ui/arrow-left.svg" />
        </div>
        <div>
          <img class="h-6 md:h-7" src="@/assets/icons/ui/cc-logo.svg" />
        </div>
      </div>
      <p class="text-sm font-normal text-black mb-12 text-center">
        You are now being redirected from {{ partnerName }} to CarClarity.
      </p>
      <spinner />
    </div>
  </div>
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  components: {
    Spinner
  },
  props: {
    partnerName: {
      type: String,
      default: ''
    },
    partnerLogo: {
      type: String,
      default: ''
    }
  }
};
</script>

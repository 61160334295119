<template>
  <div class="repayments m-auto p-4 bg-white" style="background: #F2F1F6">
    <div
      class="flex flex-col items-start xs:flex-row xs:justify-between xs:items-center mb-3.5"
    >
      <div class="mb-4 xs:mb-0">
        <p class="text-sm text-black font-bold mb-2">Payments from</p>
        <div class="flex items-end">
          <h1 class="text-base-2.5 font-bold text-black">$145</h1>
          <p class="ml-1.5 text-xs text-black mb-1 font-medium">
            / per week*
          </p>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <button
          class="bg-primary text-white py-2.5 px-6 rounded-full mb-2 text-sm font-bold"
          @click="onGetOptionsClick"
        >
          Get options now
        </button>
        <p class="text-xxs text-grey-dark font-medium">
          No credit score impact
        </p>
      </div>
    </div>
    <div class="border-t border-solid border-grey-light">
      <p class="text-grey-dark text-xxs pt-4 font-normal">
        * Weekly repayments are based on a secured car loan of $29,880 over a 5
        year term for a new car at a 6.5% interest rate and 7.2% comparison
        rate.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onGetOptionsClick() {
      const carClarity = {
        type: 'getOptionClicked',
        ...this.$route.query
      };
      window.parent.postMessage(carClarity, '*');
    }
  }
};
</script>

<style lang="scss">
@import '../assets/styles/partials/fonts';
// body {
//   background: #f2f1f6 !important;
// }
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import segment from '@/plugins/segment';
import LoanCalculator from '@/components/LoanCalculator';
import Repayments from '@/components/Repayments';
import WhiteLabelForm from '@/components/WhiteLabelForm';

import WidgetLoanCalculatorModal from '@/widgets/LoanCalculatorModal';
import WidgetCalculator from '@/widgets/Calculator';
import WidgetJourneyFinance from '@/widgets/JourneyFinance';
import WidgetAffordabilityCalculatorModal from '@/widgets/AffordabilityCalculator';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { toCurrency } from '@/utils/currency';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';
import vueCustomElement from 'vue-custom-element';
import '@/assets/css/tailwind.css';

library.add(faInfoCircle);
library.add(faTimes);

// tool tips
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);
Vue.filter('currency', toCurrency);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(segment);
Vue.use(VueCookies);
Vue.use(VueRouter);
const routes = [
  { path: '/', component: LoanCalculator },
  { path: '/calculator-cta-launcher', component: LoanCalculator },
  { path: '/repayments', component: Repayments },
  { path: '/white-label-form', component: WhiteLabelForm },
  // # Widgets
  { path: '/get-loan-options', component: WidgetJourneyFinance },
  { path: '/loan-calculator-modal', component: WidgetLoanCalculatorModal },
  {
    path: '/affordability-calculator-modal',
    component: WidgetAffordabilityCalculatorModal
  },
  { path: '/calculator-cta', component: WidgetCalculator }
];
const router = new VueRouter({
  mode: 'history',
  routes
});
// custom element
Vue.use(vueCustomElement);
Vue.customElement('calculator-cta-launcher', LoanCalculator);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
